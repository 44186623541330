/*
 Pagination verbindet bootstrap pagination mit Custom Styles
 */

.paging {
  @extend %list-unstyled; // lists

  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  /* reset problem of lists */
  ul li + li {
    margin-top: 0;
  }

  .pagination-wrapper, .pagination-entries {
    width: 100%;
  }

  .pagination-entries {
    select {
      height: auto;
      padding-right: $spacer-6;
    }
  }

  .pagination {
    margin-bottom: 2rem;
    justify-content: space-between;
    font-size: .8em;

    @include media-breakpoint-up(md) {
      font-size: inherit;
    }

  }

  .page-item {
    font-weight: 700;

    .page-link {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      padding: 0;
      border-radius: 100%;
      height: 2.6em;
      width: 2.6em;

      &:before {
        padding: 0 !important;
      }
    }

    &.active {
      .page-link {
        color: $color-white;
        background-color: $primary;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .paging {
    .pagination-wrapper {
      width: auto;
    }

    .pagination {
      margin-bottom: 1rem;
      justify-content: initial;
    }

    .pagination-entries {
      width: auto;
    }
  }
}
