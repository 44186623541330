.basket {

  thead tr th {
    white-space: nowrap;

    &.right {
      text-align: right;
    }
  }

  tbody tr td {
    vertical-align: middle;
    border-bottom: 1px solid $color-gray-medium-light;
    padding: $spacer-3;

    &.right {
      text-align: right;
    }
  }

  tfoot tr td {
    &.right {
      text-align: right;
    }
  }

  a {
    text-decoration: none;

    > [class*='icon-'] {
      font-size: px2rem(21);
    }
  }
}

.basket-sidebar {
  position: relative;

  h6 {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    .basket-info {
      font-size: .9375rem;
      font-weight: 400;
    }
  }

  table tbody tr td {
    border-bottom: none;
    padding: $spacer-2 0;

    + td {
      padding-left: $spacer-2;
    }
    .product-image-box {
      min-width: 2.6rem;
    }
  }

  .btn {
    display: block;
    @include media-breakpoint-up(sm) {
      display: inline-block;
    }
  }

}

.lt-publikationen__item-wrapper {
  @extend .basket;

  h3 {
    @extend %heading-h6;
    margin-top: 0;
  }

  .lt-publikationen__meta {
    font-weight: bold;
    color: $gray-700;

    time, > span {
      font-weight: normal;
      color: $gray-700;
    }
  }

  .amount {

    @include media-breakpoint-up(sm) {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: flex-end;
    }

    label {
      padding-left: 0;
    }

    button[type=submit] {
      display: block;
      width: 100%;
    }
  }

  .basketitemoptions {
    display: inline-block;
    border: 1px solid $gray-700;
    padding: .5rem 1rem;

    em {
      color: $gray-700;
    }
  }
}

.shopping-cart {

  thead th.cart-quantity {
      &:before{
        display: block;
        height:0;
        content: "";
        min-width: 250px;
      }
  }
  thead th.cart-price {
    &:before{
      display: block;
      height:0;
      content: "";
      min-width: 100px;
    }
  }

  .cart-price {
    text-align: right; 
  }
  .reload, .delete {
    display: inline-flex;
    font-size: 1.25rem;
    padding: 0;
    margin: $spacer-1;
    border: 0;
    background-color: transparent;
    color: $primary;
    cursor: pointer;

    &:hover,&:focus,&:active {
      color: darken($link-color, 10%);
    }
  }
  
  .product-image {
    width: px2rem(90);
    margin-right: $spacer-2;
    display: block;
    float: left;
  }

  .ctrl {
    display: inline-flex;
    margin: $spacer-2 $spacer-2 $spacer-2 0;
  }

  tfoot {
    td {
      border-top: 0 none;
      border-bottom: 3px double #a2a2a2;
    }
  }

  .cart-summary {
    margin-bottom: .5rem;

    @include media-breakpoint-up(md) {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 0;
    }

    .info {
      margin-bottom: 1em;
    }
    .summary-price {
      flex: 0 0 auto;
    }
    .price-entry {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      > *:first-child {
        margin-right: $spacer-4;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .shopping-cart {
    tr td{
      &:before {
        width: 40%;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .shopping-cart {

    tr,
    td,
    tbody,
    thead,
    tfoot,
    th {
      display: table;
      width: 100%;
      margin: .5em 0;
    }

    thead tr:first-child {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      td {
        border: 0 none;

        &[title]:before {
          content: attr(title) ": ";
        }

        &:before {
          white-space: nowrap;
          width: 30%;
          display: table-cell;
          text-align: left;
          font-weight: $font-weight-bold;
          padding: .5em 0;
        }

        &.cart-quantity {
          text-align: right;
        }

        &:last-child {
          border-bottom: 2px solid #a2a2a2;
        }
      }
    }
  }
}




