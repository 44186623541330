.lt-wahlkreise__karte {
  margin-top: $spacer-5;

  // IE 11 Fix
  .svg-container {
    width: 100%;
    height: 50vh;

    @include media-breakpoint-up(lg) {
      margin-right: $spacer-4;
    }
  }


  @include media-breakpoint-up(xl) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    & .svg-container, & .lt-wahlkreise__box {
      flex: 1;
    }

    // IE 11 Fix
    .svg-container {
      flex: 1;
      width: 100%;
      min-height: 600px;
      margin-right: $spacer-6;
    }
  }

  .popover-body {
    display: none !important;
  }
}

.lt-wahlkreise__box {
  margin-top: $spacer-5;

  @include media-breakpoint-up(xl) {
    margin-top: 0;
  }
}

.lt-wahlkreise__list {
  margin-top: $spacer-4;

  ul {
    margin-top: $spacer-3;
    @extend %list-unstyled;

    li:first-child {
      border-top: 1px solid $color-gray-medium-light;
    }

    @include media-breakpoint-up(lg) {
      display: block;
      column-count: 2;
    }
  }
}
