%highlight-box {

}

.highlight-box {
  @extend %highlight-box;
  background: $color-gray-light;
}

.highlight-box-outline {
  @extend %highlight-box;
}
