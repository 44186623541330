.autocomplete-input {
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

[data-position=below] .autocomplete-input[aria-expanded=true] {
  border-bottom-color: transparent;
}

[data-position=above] .autocomplete-input[aria-expanded=true] {
  border-top-color: transparent;
  z-index: 1000;
}

.autocomplete-result-list {
  margin: 0;
  border: 1px solid rgba(0, 0, 0, .12);
  padding: 0;
  box-sizing: border-box;
  max-height: 296px;
  overflow-y: auto;
  background: #fff;
  list-style: none;
  box-shadow: 0 2px 2px rgba(0, 0, 0, .16);
  z-index: 1000 !important;
}

.autocomplete-result-list li {
  display: block;
  text-align: left;
  font-weight: normal;
}

[data-position=below] .autocomplete-result-list {
  margin-top: -1px;
  border-top-color: transparent;
  padding-bottom: 2px;
}

[data-position=above] .autocomplete-result-list {
  margin-bottom: -1px;
  border-bottom-color: transparent;
  padding-top: 2px;
}

.autocomplete-result {
  cursor: default;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
}

.autocomplete-result:hover, .autocomplete-result[aria-selected=true] {
  background-color: rgba(0, 0, 0, .06)
}
