@use 'sass:math';

$gallery-spacer: $spacer-4;
$gallery-item-spacer: $spacer-3;

.gallery {
  margin-top: $gallery-spacer;
  margin-bottom: $gallery-spacer;
  background-color: $color-gray-light;
  padding: $gallery-spacer;

  .images {
    display: flex;
    flex-flow: row wrap;
    margin-left: $gallery-item-spacer * -1;
    margin-right: $gallery-item-spacer * -1;

    > a {
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;
      position: relative;

      &:before {
        //padding-top: 100%;
        padding-top: math.div(2, 3) * 100%; //3:2 Format
        content: "";
        display: block;
      }

      figure {
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - $gallery-item-spacer*2);
        height: calc(100% - $gallery-item-spacer*2);
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        margin: 0;
        padding: 0;
        background-color: transparent;
        z-index: 4;

        > img {
          max-width: 100%;
          max-height: 100%;
          width: auto;
          height: auto;
          display: block;
          position: absolute;
          margin: $gallery-item-spacer;
          top:50%;
          left: 50%;
          transform: translate(-50%,-50%);
          vertical-align: center;
        }
      }

    }
  }


  button {
    margin-top: $spacer-3;
  }

  @include media-breakpoint-up(sm) {

    .images {
      > a {
        flex: 0 0 math.div(100%, 3);
        max-width: math.div(100%, 3);
      }
    }
  }
}
