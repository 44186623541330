@use "sass:math";

$widget-list-breakpoint: md;

.widget-list {
  margin-top: $spacer-6;
  margin-bottom: $spacer-6;
  align-items: stretch;

  // teaser list overrides
  .teaser-list {
    .teaser-title {
      font-size: px2rem(18);
    }

    article::after {
      content: none;
    }

    .teaser-img {
      margin-top: $spacer-2;
      margin-bottom: $spacer-2;

      @include media-breakpoint-up(sm) {
        &:before {
          font-size: 1em;
        }
      }
    }

    .teaser-text {
      margin-top: $spacer-2;
      margin-bottom: $spacer-2;
    }
  }
}

.widget {
  margin-top: $spacer-4;
  margin-bottom: $spacer-4;

  @include media-breakpoint-up(xxl) {
    margin-top: 0;
    margin-bottom: 0;
  }

  &__content {

    display: flex;
    flex-flow: row wrap;
    //justify-content: flex-start;
    //align-items: flex-start;
    //height: 100%;

    & > * {
      flex: 1 1 100%;
    }

    h6 {
      margin-bottom: $spacer-2;
    }

    .diary {
      margin-top: $spacer-2;
    }
  }

  &__link {
    //margin-top: auto;
    padding-top: $spacer-2;
  }
}

// mobile only
@include media-breakpoint-down($widget-list-breakpoint) {
  .widget {
    margin-top: $spacer-4;
  }
}

@include media-breakpoint-up($widget-list-breakpoint) {
  .widget .box.shadow {
    box-shadow: none !important;
  }
}


.widget-parliamentary-groups {
  margin-top: $spacer-6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row wrap;

  &:not(:last-child) {
    margin-bottom: $spacer-6;
  }

  h6 {
    flex: 1 0 100%;
    align-self: flex-start;
    margin-bottom: $spacer-3;
  }

  .group-logo {
    flex: 0 0  math.div(100%, 2);
    max-width: math.div(100%, 2);
    text-align: center;
    padding: $spacer-1 $spacer-3;

    @include media-breakpoint-up(md) {
      flex: 0 0 math.div(100%, 3);
      max-width: math.div(100%, 3);
    }
    @include media-breakpoint-up(lg) {
      flex: 0 0 math.div(100%, 6);
      max-width: math.div(100%, 6);
    }

    img {
      display: block;
      width: 100%;
      height: 6rem;
      object-fit: contain;
      object-position: center center;
    }
  }

}

.topnews {
  a {
    text-decoration: none;
    color: inherit;

    * {
      transition: color $link-transition-duration $link-transition-type;
     }

    &:hover, &:active, &:focus{
      .topnews-meta, .topnews-title {
        color: $primary;
      }

      .topnews-img {

        img {
          transform: scale(1.04);
        }

        &:after {
          transform: translateY(0%);
        }
      }
    }
  }

  .topnews-meta {
    margin: $spacer-2 0;
    color: $color-gray-base;
  }

  .topnews-title {
    margin-top: 0;
  }

  .topnews-img {
    overflow: hidden;
    margin-bottom: $spacer-3;
    position: relative;

    &:after {
      height: 4px;
      width: 100%;
      background-color: $primary;
      display: block;
      content: '';
      position: absolute;
      z-index: 4;
      bottom: 0;
      transform: translateY(100%);
      transition: all $link-transition-duration $link-transition-type;
    }

    img {
      display: block;
      height: auto;
      width: 100%;
      transition: all $link-transition-duration $link-transition-type;
    }

  }
}
