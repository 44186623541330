$slick-font-family: Icons;
$slick-loader-path: '../static/img/';
$slick-prev-character: map-get($icon-map, 'next');
$slick-next-character: map-get($icon-map, 'next');
$slick-arrow-color: $color-red;
$slick-dot-color: $color-black;
$slick-dot-size: $font-size-h3-desktop;
$slick-opacity-default: 1;
$slick-opacity-on-hover: 1;

$slick-breakpoint: md;

@import '../../../node_modules/slick-carousel/slick/slick.scss'; // we need *.scss here, parcel will not build otherwise
@import '../../../node_modules/slick-carousel/slick/slick-theme.scss';

.multi-slides {
  margin-bottom: $spacer-6 !important;

  img {
    max-width: 100%;
  }
}

.multi-slides-more {
  margin-left: $gutter-width * -1;
  margin-right: $gutter-width * -1;
}

.slick-prev, .slick-next {
  width: auto;
  height: auto;
  z-index: $zindex-dropdown;
  text-align: center;
  background-color: $color-white;
  padding: $spacer-2 0;

  &:hover, &:focus {
    background-color: $color-white;
  }

  &:hover::before {
    color: darken($slick-arrow-color, 10%);
  }

  &::before {
    font-size: $font-size-h3-desktop;
  }
}

.slick-dots {
  @extend %list-unstyled;
}

.slick-prev::before {
  display: inline-block;
  content: map-get($icon-map, 'left-big');
}

.slick-next::before {
  display: inline-block;
  content: map-get($icon-map, 'right-big');
}

.slick-dots li.slick-active button:before {
  color: $color-red;
}

@include media-breakpoint-down($slick-breakpoint) {
  .multi-slides {
    margin-left: $gutter-width * -1;
    margin-right: $gutter-width * -1;
  }

  .slick-prev {
    left: 0;
  }

  .slick-next {
    right: 0;
  }
}

@include media-breakpoint-up($slick-breakpoint) {
  .multi-slides {
    margin-bottom: $spacer-2 !important;

    .slick-slide {
      padding: $spacer-3;
    }
  }
  .multi-slides-more {
    margin-left: 0;
    margin-right: 0;
  }
}

@include media-breakpoint-up(lg) {
  .multi-slides-more {
    margin-left: $gutter-width;
    margin-right: $gutter-width;
  }
}
