//=== GLOBAL FOOTER

$footer-bg: $color-gray-light;
$footer-color: $color-gray-dark;

.footer {
  background-color: $footer-bg;
  color: $footer-color;
  padding: $spacer-3 0 $spacer-2;
  position: relative;

  .btn-circle {
    width: 3rem;
    height: 3rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-flow: row nowrap;
    background-color: $primary;
    border-radius: 100%;
    margin: $spacer-2 $spacer-1;
    color: $white;
    text-decoration: none;
    transition: all $link-transition-duration $link-transition-type;

    &:hover, &:active, &:focus {
      background-color: darken($link-color, 10%);
    }
  }

  .jump-up {
    .to-top {
      &:before {
        @extend %icon-content;
        content: map-get($icon-map, 'chevron-up');
        font-size: 1.8em !important;
        padding: 0 !important;

      }
    }
  }

  .container > .row:first-child [class^="col-"] {
    margin-top: $spacer-4;

    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }

  > div {
    background: $footer-bg url("./img/bg.svg") no-repeat right top;
    background-size: auto 400px;
    display: flex;
    flex-direction: column;

    .follow-us {
      text-align: center;

      h6 {
        margin: 1rem 0;
      }

      > a::before {
        font-size: 1.5rem;
        padding: 0;
      }

      .btn-circle {
        margin: $spacer-2 .6rem;
      }
    }

    .list-links {
      li + li {
        margin-top: $spacer-2;
      }
    }
  }

  /* Altsystem Problematik h2 in Modulen */
  h2 {
    @extend %heading-h6;
  }

  h2, h6 {
    font-size: px2rem(20);
    margin: 1rem 0;
  }

  /* Icon Adresse an die 1. Überschrift anhängen */
  h2.address {
    &:after {
      @extend %icon-content;
      content: map-get($icon-map, 'laptop');
      padding-left: $spacer-2;
      padding-right: 0;
    }
  }

  a.icon-link::before {
    vertical-align: bottom;
  }

}

.meta-footer {
  border-top: 1px solid $color-gray-medium;
  display: flex;
  text-align: center;
  flex-direction: column-reverse;
  margin-top: $spacer-2;

  > .col {
    flex: unset;
    width: auto;
    margin-top: $spacer-2;
  }

  nav > ul {
    width: auto;

    li {
      margin: $spacer-4 0;
    }
  }

  a {
    color: $body-color;
  }

  .copyright {
    max-width: 90%;
    text-align: left;
    display: flex;
    flex-flow: row wrap;
    font-size: px2rem(12);

    @include media-breakpoint-up(md) {
      font-size: px2rem(14);
    }
    @include media-breakpoint-up(lg) {
      font-size: px2rem(16);
    }

    span {
      display: inline-block;
    }
  }
}


@include media-breakpoint-up($breakpoint-desktop) {
  .footer {
    padding: $spacer-4 0 $spacer-3;

    > .container {
      padding-left: $spacer-4;
      padding-right: $spacer-4;

      h6 {
        margin-bottom: $spacer-4;
      }

      .follow-us {
        text-align: left;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }

  .meta-footer {
    font-size: .8em;
    padding: $spacer-2 0;
    text-align: left;
    flex-direction: unset;

    nav {
      margin-left: auto;

      > ul {
        text-align: right;

        li {
          display: inline-block;
          margin: 0 0 0 $spacer-2;
        }
      }
    }
  }
}
