.dsgvo {
  opacity: 0;
  text-align: center;
  background-size: 50% auto;
  position: relative;
  padding: 0;

  label {
    user-select: none;
    font-weight: normal;
  }

  &.loaded { opacity: 1; }

  &.video-center {
    margin: $spacer-6 auto;
    max-width: px2rem(800); /* 50rem */
  }

  &-accepted {
    padding-bottom: 0;
    height: auto;
    background: none;
  }

  .p-overlay {
    font-size: .875rem;
    display: block;
    padding: 2em;
    position: relative;

    p {
      max-width: 66%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  button.accept {
    @extend %heading-h4;
    cursor: pointer;
    overflow: visible;
    text-decoration: none;
    appearance: none;
    border: none;
    background-color: transparent;
    outline: none;

    &[disabled] {
      pointer-events: none;
      cursor: not-allowed;

      .icon-play-button {
        color: $color-gray-base;
        opacity: .3;
      }
    }

    .icon-play-button {
      display: block;
      font-size: 6em;
      line-height: 1;
      color: $color-red;
      margin-bottom: $spacer-2;
      transition: $transition-base;

      @include media-breakpoint-up(md) {
        font-size: 6em;
      }
      @include media-breakpoint-up(lg) {
        font-size: 4em;
      }
      @include media-breakpoint-up(xl) {
        font-size: 6em;
      }
    }
  }
}

/* Cookies und/oder AGB akzeptiert */
.dsgvo {
  background: $color-gray-light url(./img/bg.svg) center center no-repeat;

  &:not(&-accepted) .embed-responsive-item,
  &-accepted .p-overlay {
    display: none !important;
  }
}

.dsgvo.agb {

  &.dsgvo-accepted {
    .p-overlay {
      display: block !important;

      @include media-breakpoint-up(md) {
        display: flex !important;
      }
    }

    &:not(.agb-accepted) .embed-responsive-item,
    &.agb-accepted .p-overlay {
      display: none !important;
    }
  }
}


@include media-breakpoint-up(md) {
  .dsgvo {
    position: relative;

    .p-overlay {
      padding: 3rem;
      height: 100%;
      width: 100%;

      position: absolute;
      top: 0;
      left: 0;

      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
    }
  }
}
