.lt-filter-ansicht {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .view-grid {
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    text-decoration: none;

    &:after {
      @extend %icon-content;
      content: map-get($icon-map, 'grid');
      padding: 0 0 0 $spacer-2;
      font-size: 1.5em;
    }
  }

  .view-list {

    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    text-decoration: none;

    &:after {
      @extend %icon-content;
      content: map-get($icon-map, 'list');
      padding: 0 0 0 $spacer-2;
      font-size: 1.5em;

    }
  }
}
