.diary {
  .relative {
    position: relative;
  }

  .input-group {
    margin-bottom: $spacer-2;
  }
  .form-control,
  .form-select {
    margin: $spacer-1;
    border-radius: 0;
  }

  .search-input {
    flex-shrink: unset;
    padding-right: 2.4rem;
    padding-left: px2rem(10);
    font-size: px2rem(16);
    border-color: $color-gray-medium-light;

    @include media-breakpoint-up(xxl) {
      font-size: px2rem(14);
    }
  }

  .icon-magnifying-glass {
    position: absolute;
    right: $spacer-1;
    top: 0;
    bottom: 0;
    z-index: 3;
    color: $color-red;
    font-size: 1.4rem;
    line-height: 1.6;
    flex-shrink: unset;
    border: none;
    background-color: transparent;
    padding-right: $spacer-2;
    cursor: pointer;

    &:before {
      padding-right: 0;
    }
  }

  .search-category {
    max-width: 71%;
    font-size: px2rem(16);

    @include media-breakpoint-up(xxl) {
      font-size: px2rem(14);
    }
  }

  .xtented-search {
    font-size: px2rem(12);
    margin-left: .5rem;
    align-self: center;
    max-width: 25%;
    text-decoration: none;
  }

  .lt-calender__navigation {
    text-align: center;
    padding: 0;
    margin: $spacer-3 auto $spacer-1 auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;

    li {
      display: inline-block;
      margin: 0;

      &.picker-switch {
        font-weight: bold;
        text-transform: uppercase;

        a {
          text-decoration: none;
        }
      }

      a.last {
        @extend %icon;

        &::before {
          content: map-get($icon-map, 'left-big');
        }
      }
      a.next {
        @extend %icon;

        &::before {
          content: map-get($icon-map, 'right-big');
        }
      }

      [class^='icon-'] {
        font-size: px2rem(16);
      }
    }

    li::before {
      content: none;
      display: none;
    }
  }

  .month {
    font-weight: bold;
    margin-bottom: .5rem;
  }

  table {
    margin: 0 auto $spacer-3 auto;
    width: calc(100% - 16px);
    white-space: nowrap;
    table-layout: fixed;

    tbody tr td {
      margin-bottom: $spacer-1;
    }

    thead tr th,
    tbody tr td {
      padding: .25rem .2rem;
      margin-left: $spacer-1;
      margin-right: $spacer-1;
      text-align: center;
      vertical-align: middle;
      height: 2rem;
      border: none;

      & + td + td + td + td + td + td + td {
        text-align: left;
        font-size: .8rem;
        white-space: normal;
      }

      &.event {
        > span {
          border-width: 2px;
          border-style: solid;
          border-radius: 100%;
          width: 2rem;
          height: 2rem;
          display: flex;
          flex-flow: row nowrap;
          justify-content: center;
          align-items: center;
          margin: 0 auto;
        }
      }
    }

    th.day {
      width: 2.2rem;
    }

    a {
      border: 1px solid $primary;
      border-radius: 100%;
      width: 2rem;
      height: 2rem;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      text-decoration: none;
    }

    .current span, .current a {
      height: 2rem;
      width: 2rem;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      color: $color-white;
      background-color: $primary;
      border: 1px solid $primary;
      border-radius: 100%;
      margin: 0 auto;
      text-decoration: none;
    }
  }
}

.timetable {
  > .col {
    margin-bottom: $spacer-4;
  }

  .events {
    @extend .diary;
    overflow-x: auto;
    height: 100%;
    margin: 0;

    > table {
      margin: 0;
      width: 100%;

      tr {
        td {
          white-space: nowrap !important;
          word-wrap: initial;
        }
        th.day {
          width: 2.4rem;
        }
      }
    }
  }
}
