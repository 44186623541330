@use 'sass:math';

//=== SPACERS
$spacers: (
        1: .25rem,
        2: .75rem,
        3: 1rem,
        4: 1.875rem,
        5: 2.5rem,
        6: 3.75rem
);

$spacer-1: map-get($spacers, 1);
$spacer-2: map-get($spacers, 2);
$spacer-3: map-get($spacers, 3);
$spacer-4: map-get($spacers, 4);
$spacer-5: map-get($spacers, 5);
$spacer-6: map-get($spacers, 6);


//=== FONTS
$font-weight-base: 400;
$font-weight-bold: 600;

$font-size-base: .875rem;
$font-size-base-desktop: px2rem(17);

$font-family-serif: 'Frank Ruhl Libre', 'Iowan Old Style', 'Apple Garamond', Baskerville, 'Times New Roman', 'Droid Serif', Times, 'Source Serif Pro', serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$font-family-sans: Montserrat, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', system-ui, -apple-system, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$font-family-mono: Menlo, Consolas, Monaco, 'Liberation Mono', 'Lucida Console', monospace;
$input-font-family: $font-family-sans; // .form-control font


$line-height-base: 1.48;
$line-height-main: 1.6;

$link-decoration: none;
$link-hover-decoration: $link-decoration;

//=== BODY
$body-color: $color-gray-dark;
$body-bg: $color-white;

$text-muted: $color-gray-base;

//=== LINKS
$link-color: $color-red;
$link-hover-color: darken($link-color, 10%);

//=== TEXT
$font-size-h1: px2rem(23);
$font-size-h2: px2rem(21);
$font-size-h3: px2rem(19);
$font-size-h4: px2rem(18);
$font-size-h5: px2rem(17);
$font-size-h6: px2rem(16);

$font-size-h1-desktop: px2rem(48);
$font-size-h2-desktop: px2rem(42);
$font-size-h3-desktop: px2rem(37);
$font-size-h4-desktop: px2rem(32);
$font-size-h5-desktop: px2rem(28);
$font-size-h6-desktop: px2rem(22);


//=== ICONS
$icon-map: (
        "five": "\f101",
        "study": "\f102",
        "magnifying-glass": "\f103",
        "language": "\f104",
        "earth-grid": "\f105",
        "envelope": "\f106",
        "laptop": "\f107",
        "twitter": "\f108",
        "facebook": "\f109",
        "instagram": "\f10a",
        "youtube": "\f10b",
        "chat": "\f10c",
        "more": "\f10d",
        "video-streaming": "\f10e",
        "video-camera": "\f10f",
        "videocamera": "\f110",
        "clock": "\f111",
        "photo-camera": "\f112",
        "view": "\f113",
        "pointer": "\f114",
        "image": "\f115",
        "filter": "\f116",
        "download": "\f117",
        "pdf": "\f118",
        "home": "\f119",
        "cart": "\f11a",
        "zooming": "\f11b",
        "zooming-1": "\f11c",
        "user": "\f11d",
        "menu": "\f11e",
        "rss": "\f11f",
        "grid": "\f120",
        "grid-1": "\f121",
        "list": "\f122",
        "bin": "\f123",
        "info": "\f124",
        "warning": "\f125",
        "gallery": "\f126",
        "picture": "\f127",
        "google-docs": "\f128",
        "contract": "\f129",
        "file": "\f12a",
        "quiz": "\f12b",
        "quiz-1": "\f12c",
        "notebook": "\f12d",
        "stamp": "\f12e",
        "list-1": "\f130",
        "calendar-add": "\f131",
        "play-button": "\f133",
        "calendar": "\f134",
        "calendar-1": "\f135",
        "calendar-2": "\f132",
        "calendar-3": "\f138",
        "calendar-4": "\f12f",
        "document": "\f136",
        "play-button-1": "\f137",
        "open-book": "\f139",
        "list-2": "\f13a",
        "chevron-right": "\f13b",
        "chevron-left": "\f13c",
        "chevron-down": "\f13d",
        "chevron-up": "\f13e",
        "download-1": "\f13f",
        "download-2": "\f140",
        "close": "\f141",
        "link": "\f142",
        "speaker": "\f143",
        "right-arrow": "\f144",
        "left-arrow": "\f145",
        "left-big": "\f147",
        "right-big": "\f146",
        "right-arrow-1": "\f148",
        "left-arrow-1": "\f149",
        "reload": "\f14a",
        "refresh": "\f14b",
        "check": "\f14c",
);


//=== HEADER
$breakpoint-desktop: lg;
$breakpoint-desktop-large: xxl;


//=== BOOTSTRAP OVERWRITE
//=== COLORS/THEMES
$primary: $color-red;

// to produce more theme-like components like: `.btn-danger` just add
// new theme option here
$theme-colors: (
        "primary": $primary,
  //"secondary":  $secondary,
  //"success":    $success,
  //"info":       $info,
  //"warning":    $warning,
  //"danger":     $danger,
  //"light":      $light,
  //"dark":       $dark
);


//=== BREAKPOINTS
$grid-breakpoints: (
        xs: 0,
        sm: 480px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px,
        xxxl: 1600px
);

//=== GRID
$grid-gutter-width: $spacer-4;
$gutter-width: math.div($grid-gutter-width, 2);

$gutter-mobile: $spacer-2;
$gutter-width-mobile: math.div($gutter-mobile, 2);


//=== CONTAINER
$container-max-widths: (
        xs: 100%,
        sm: 100%,
        md: 100%,
        lg: 100%,
        xl: 100%,
        xxl: 100%,
        xxxl: 1600px
);

//=== BORDERS
$border-color: gray;
$border-radius: .125rem;

//=== PAGINATION VARIABLES
$pagination-bg: transprent;
$pagination-border-width: 0;
$pagination-border-radius: 0;
$pagination-hover-bg: $color-gray-light;
$pagination-focus-bg: $color-gray-light;
$pagination-active-color: $color-white;
$pagination-active-bg: transparent;

$pagination-focus-box-shadow: none;
$pagination-padding-x: 1.3rem;
$pagination-padding-y: 0.8rem;

//=== FORMS
$input-height: 3.375rem;
$input-height-sm: 2.5rem;
$input-padding-x: 1.1rem;
$input-padding-y: 0.85rem;
$input-color: $color-gray-dark;
//$input-focus-box-shadow: 0 0 0 0.2rem rgba($primary, .25);
$input-placeholder-color: $color-gray-base;
$input-border-color: $color-gray-medium-light;
$input-font-size: inherit; // von body übernehmen - media queries beachten
$font-size-sm: px2rem(13);


$btn-padding-x: $input-padding-x;
$btn-padding-y: $input-padding-y;

//=== SELECT
$form-select-border-width: 1px;
$form-select-border-radius: 0;
$form-select-border-color: $color-gray-medium-light;
$form-select-focus-border-color: $color-gray-medium-light;
$form-select-focus-box-shadow: none;
$form-select-indicator-color: $link-color;
$form-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='1' d='M2 5l6 6 6-6'/></svg>");
$form-select-bg-size: 20px 20px;

//=== BUTTONS
$btn-font-family: $font-family-sans;

//=== LIST-GROUP
$list-group-border-color: $color-gray-medium-light;
$list-group-border-radius: 0;

//=== POPOVER TOOLTIP
// Design fuer Wahlkreiskarte
//$popover-font-size: 2rem;
$popover-bg: rgba(0, 0, 0, 0.5);
$popover-body-bg: $popover-bg;
$popover-arrow-width: 0;
$popover-arrow-height: 0;
$popover-border-width: 0;
$popover-border-radius: 0;
$popover-header-color: $color-white;
$popover-body-color: $popover-header-color;

//=== BREADCRUMB
$breadcrumb-bg: transparent;


//=== TRANSITIONS
$link-transition-duration: .4s;
$link-transition-type: ease-in-out;