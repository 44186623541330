// hide content visually while keeping it accessible
// @source https://github.com/twbs/bootstrap/blob/main/scss/mixins/_visually-hidden.scss
// and for screen reader
.visually-hidden, .sr-only {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important; // Fix for https://github.com/twbs/bootstrap/issues/25686
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

@include media-breakpoint-up($breakpoint-desktop) {
  .hidden-lg {
    display: none !important
  }
}

@include media-breakpoint-up(md) {
  .hidden-md {
    display: none !important
  }
}

.hidden {
  display: none !important;
}

// overrides bootstrap styles
.form-control {
  border-radius: 0;

  &:focus {
    border-color: $input-border-color;
    //box-shadow: none;
  }
}

.time-line {
  width: 100%;
  display: flex;
  align-items: center;
  &::before {
    @extend %icon-content;
    content: map-get($icon-map, 'clock');;
  }
  &::after {
    content: '';
    height: 1px;
    flex-grow: 1;
    margin-left: $spacer-3;
    border-bottom: 1px solid $body-color;
  }
}
