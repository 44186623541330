//=== BREADCRUMB
@use "sass:math";

.breadcrumb {
  margin: 0 0 $spacer-4 0;
  padding-left: $spacer-4;
  position: relative;
  font-size: .75rem;

  @include media-breakpoint-up(md) {
    font-size: .875rem;
  }

  .breadcrumb-item {
    padding: 0;

    a {
      text-decoration: none;
    }

    &:first-child {
      position: absolute;
      left: 0;
    }

    &.active {
      color: $color-gray-base;
    }

    & + .breadcrumb-item::before {
      content: '';
      display: none;
    }

    & + .breadcrumb-item:not(:last-child)::after {
      display: inline-block;
      padding: 0 $spacer-2;
      color: $color-gray-base;
      content: '/';
    }
  }

  [class*='icon'] {
    font-size: 1.2em;
    color: $link-color;
    line-height: 1;
  }
}
