@use 'sass:math';

//=== BADGE

$badge-bg: $color-red;
$badge-color: $color-white;
$badge-padding-y: .35em !default;
$badge-padding-x: .65em !default;
$badge-size: .65rem;
$badge-border-width: px2rem(2);

%badge {
  display: inline-flex;
  border-radius: $badge-size;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;
  min-height: 1.6em;
  min-width: 1.6em;
  line-height: 1;
  font-size: $badge-size;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  border: $badge-border-width solid transparent;
  padding: 0 .2em;
  //position: relative;
  //top: -#{$badge-border-width};

  &:empty {
    display: none;
  }
}

.badge {
  @extend %badge;
  color: $badge-color;
  background-color: $badge-bg;
}

.badge-outline {
  @extend %badge;
  color: $badge-bg;
  background-color: $badge-color;
  border-color: $badge-bg;
}
