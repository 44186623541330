@use 'sass:math';

$mobile-menu-icon-size: px2rem(26);
$font-size-main-nav: px2rem(18);
$font-size-main-nav-desktop: px2rem(20);
$font-size-main-nav-sub: px2rem(16);
$font-size-main-nav-sub-desktop: px2rem(16);
$font-size-main-nav-sub-entry: px2rem(15);
$font-size-main-nav-sub-entry-desktop: px2rem(15);
$font-size-top-nav: px2rem(14);
$transition-timing-main-nav-arrow: ease-in-out .2s;
$mobile-menu-padding-y: .8rem;
$header-box-shadow: 0 4px 12px 4px rgba(0, 0, 0, .3);
$main-nav-calculated-offset: 150px; // height of .mobile-menu + meta-nav

.header {

  .logo {
    position: absolute;
    top: $spacer-3;
    width: 130px;
    transition: all $link-transition-duration $link-transition-type;
  }

}

//=== MOBILE MENU

.mobile-menu-buttons {
  text-align: right;
  padding: $spacer-2 $spacer-1 $spacer-4 $spacer-1;
  margin-left: math.div($gutter-width-mobile, -2);
  margin-right: math.div($gutter-width-mobile, -2);


  .toggle-menu,
  .toggle-search {
    text-decoration: none;
    margin-left: auto;
    margin-right: $spacer-1;

    > [class*=icon-] {
      font-size: $mobile-menu-icon-size;
    }
  }

  .search-mobile {
    position: absolute;
    background-color: $color-white;
    top: 4.2rem;
    left: 0;
    width: 100%;
    vertical-align: bottom;
    padding: 1rem .5rem;
    box-shadow: 0 14px 8px -10px rgba(0, 0, 0, 0.3);
    z-index: 1;

    .search-input {
      input {
        padding-right: 3.2rem;
        width: 100%;
        appearance: none;
        font-size: 1rem;
      }

      button {
        position: absolute;
        right: 0;
        background: none;
        border: none;
        z-index: 10;
        padding: 0.5rem 0.8rem .3rem 0;

        .icon-magnifying-glass {
          font-size: $mobile-menu-icon-size;
          color: $color-red;
          pointer-events: none;
          line-height: 1.4;
        }
      }
    }
  }

  .toggle-menu.active .icon-menu::before,
  .toggle-search.active .icon-magnifying-glass::before {
    content: map-get($icon-map, 'close');
  }
}

//=== MENU

.main-nav,
.meta-nav {

  position: relative;

  ul {
    @extend %list-unstyled;
  }

  li {
    display: inline-block;
  }

  a {
    text-decoration: none;
    color: $body-color;
    display: block;
  }

  .dropdown-toggle::after {
    display: none;
  }

  .dropdown-menu {
    min-width: unset;
    padding: 0;
    position: relative;
    z-index: 10000;

    li {
      display: block;
      margin: 0;
      text-align: right;

      .dropdown-item {
        &.active {
          color: $color-gray-dark;
          background-color: $color-gray-light;
        }

        &:active {
          color: $color-gray-dark;
          background-color: $color-gray-medium-light;
        }
      }
    }
  }
}

//=== META NAV
.meta-nav {
  white-space: nowrap;
  line-height: 1.6;

  li {
    margin: 0 0 0 $spacer-4;
  }

  a {
    color: $color-gray-medium;
    font-size: $font-size-top-nav;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    transition: color $link-transition-duration $link-transition-type;

    .badge {
      margin-left: .4rem;

      @include media-breakpoint-up(lg) {
        margin-left: 0;
      }
    }

    &:hover, &:focus, &:active {
      color: $color-gray-dark;
    }
  }

  a > [class*='icon-'] {
    font-size: 1rem;
  }
}

.main-nav {
  font-weight: $font-weight-bold;

  > ul {
    margin-bottom: $spacer-3;

    li + li {
      margin-top: 0;
    }
  }

  &__link {
    text-transform: uppercase;
    transition: color $link-transition-duration $link-transition-type;

    &:hover, &:focus, &:active {
      color: $primary;
    }

    .icon-next {
      display: none;
    }
  }

  .icon-magnifying-glass {
    font-size: 1.4em;
  }

  .footer-links {
    border-top: 1px solid $color-gray-light;
    margin-bottom: $spacer-2;

    > li {
      margin-top: $spacer-3;
       > a {
        font-weight: 100;
       }
    }
  }
}

//=== GIANT SUBMENU
.main-nav__sub {
  display: none;
  font-weight: $font-weight-base;

  // hide submenu if parent is NOT .active
  @at-root a.active + & {
    display: block;
  }

  .container {
    display: flex;
    flex-direction: column;

    h6 {
      font-size: $font-size-main-nav-sub;
      text-transform: uppercase;
    }

    ul li a {
      font-size: $font-size-main-nav-sub-entry;
      transition: color $link-transition-duration $link-transition-type;
      padding: .2rem 0;

      &:hover, &:focus, &:active {
        color: $primary;
      }
    }
    > * {
      padding: $spacer-1 0;
    }
  }

  li {
    padding: $spacer-1 0;
  }
}

//=== DESKTOP
@include media-breakpoint-up($breakpoint-desktop) {
  .header {
    .logo {
      position: absolute;
      z-index: $zindex-dropdown;
      top: px2rem(40);
      width: px2rem(135);
    }

    > .container {
      padding-left: $spacer-4;
      padding-right: $spacer-4;
    }
  }

  .main-nav,
  .meta-nav {
    display: block;
    text-align: right;
    padding-top: $spacer-3;
    padding-bottom: $spacer-3;

    ul {
      margin-left: auto
    }
  }

  .main-nav > ul {
    margin-right: 3rem;
  }

  .main-nav {
    margin-top: $spacer-3;
    font-size: 14px;
    min-height: 85px; /* falls keine Menu vorhanden ist */

    > ul > li + li {
      margin-left: $spacer-3;
    }

    &__link {
      font-size: px2rem(18);
      padding: px2rem(12) 0 px2rem(8) 0;

      &.active {
        color: $link-color
      }
    }

    &__sub {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      background-color: $body-bg;
      flex-direction: row;
      z-index: $zindex-dropdown;
      text-align: left;
      box-shadow: 0 18px 22px -20px rgba(0, 0, 0, 0.6);

      &::before, &::after {
        content: "";
        display: block;
        width: 50%;
        height: 100%;
        background: $color-white;
        position: absolute;
        overflow: hidden;
        top: 0;
      }

      &::before {
        left: calc(-50% + 1px); // 4k scaling problem fix
      }

      &::after {
        right: calc(-50% + 1px); // 4k scaling problem fix
      }

      h6 {
        font-size: $font-size-main-nav-sub-desktop;
      }

      li {
        display: block;
      }

      .container {
        z-index: $zindex-dropdown;
        display: flex;
        flex-direction: row;
        padding-top: $spacer-4;
        padding-bottom: $spacer-4;
        margin: (-1 * $spacer-2);

        > * {
          flex-basis: math.div(100%, 4);
          max-width: math.div(100%, 4); //IE fix
          padding: $spacer-2 $spacer-2;
        }

        &::before {
          content: '';
          height: 2px;
          background-color: $color-red;
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
        }

        ul li a {
          font-size: $font-size-main-nav-sub-entry-desktop;
          padding: 0;
        }
      }
    }

    .search-desktop {
      position: absolute;
      right: 0;
      top: 1.2rem;

      &.active {
        .autocomplete {
          height: 100%;
        }

        .search-input {
          width: 992px;
          height: $input-height;

          input {
            width: 992px;
            opacity: 1;

            & + .icon-magnifying-glass {
              color: $color-red;
              padding-right: .8rem;

              button {
                pointer-events: auto;
              }
            }
          }
        }
      }

      .search-input {
        transition: width 0.4s ease-in-out;

        input {
          position: absolute;
          right: 0;
          opacity: 0;
          transition: width 0.4s ease-in-out;
          font-size: 1.4rem;
          padding-right: 3.2rem;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          width: 0;
        }

        .icon-magnifying-glass {
          line-height: 2;
          font-size: 1.6rem;
          position: absolute;
          right: 0;
          pointer-events: none;
          z-index: 10;
          transition: all $link-transition-duration $link-transition-type;

          button {
            position: absolute;
            top: 0;
            right: 0;
            background: none;
            border: none;
            pointer-events: none;
            height: 100%;
            width: 100%;
            padding: 0.5rem 0.4rem .3rem 0;
          }
        }
      }
    }
  }

  .mobile-menu-buttons {
    display: none;
  }
}

@include media-breakpoint-up(xl) {
  .header {
    .logo {
      top: px2rem(44);
      width: px2rem(242);
    }
  }
}

@include media-breakpoint-up($breakpoint-desktop-large) {
  .header {
    .logo {
      top: px2rem(34);
      width: px2rem(272);
    }
  }

  .main-nav {
    &__link {
      font-size: $font-size-main-nav-desktop;
    }
  }
}

//=== MOBILE ONLY
//@include media-breakpoint-down($breakpoint-desktop) {
@include media-breakpoint-down(md) {
  .header-menu {
    display: none;
    position: absolute;
    left: 0;
    right: 0;

    .header-wrapper {
      padding: 0 0.938rem;
      position: relative;
      z-index: $zindex-dropdown;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
    }

    &::before {
      top: 0;
      background-color: $color-white;
      z-index: $zindex-dropdown - 1;
    }

    &::after {
      z-index: $zindex-dropdown - 2;
      height: 1rem;
      box-shadow: $header-box-shadow;
    }

    &.active {
      display: block;
      border-top: px2rem(2) solid $color-red;
      z-index: 1001;
    }
  }

  .meta-nav {
    margin-top: $spacer-3;
    line-height: 2.7;

    > ul {
      display: flex;
      flex-wrap: wrap;

      > li {
        flex-basis: 50%;
        margin: 0;

        &:nth-last-child(2n+1):not(:first-child) {
          > a {
            justify-content: flex-end;
          }
        }

        // reorder
        $items: 6;
        @for $i from 2 through $items {
          &:nth-child(#{$i}) {
            order: #{$items + 2 - $i};
          }
        }
      }
    }

    .dropdown-menu {
        width: 50%;
    }

  }

  .main-nav {
    font-size: $font-size-main-nav;
    //max-height: calc(80vh - #{$main-nav-calculated-offset});
    overflow-x: hidden;
    overflow-y: auto;


    .search-desktop {
      display: none
    }

    li {
      display: block
    }

    // main menu
    &__link {
      border-top: 1px solid $color-gray-light;
      position: relative;
      padding-top: $spacer-2;
      padding-bottom: $spacer-2;
      padding-right: $spacer-3;

      [class^='icon-'] {
        display: inline-flex;
        transition: transform #{$transition-timing-main-nav-arrow};
        position: absolute;
        right: 0;
        transform: rotate3d(0, 0, 1, 90deg);
        color: $link-color;
        pointer-events: none;
        font-size: $font-size-main-nav;
      }

      &.active [class^='icon-'] {
        transform: rotate3d(0, 0, 1, -90deg);
      }
    }

    &__sub {
      border-top: 1px solid $color-gray-light;

      .container {
        padding-left: 0;
        padding-right: 0;
        padding-top: $spacer-2;

        > div:not(:first-child) {
          margin-top: $spacer-2;
        }

      }
    }
  }
}

//=== Keyvisual

.keyvisual {
  display: none;
  background-color: $color-white;
  position: relative;
  z-index: -1;

  @include media-breakpoint-up(lg) {
    display: block;
    margin-bottom: -4%;
  }

  @media (min-width: 1600px) {
    margin-bottom: -88px;
  }

  .keyvisual_inner {
    padding: 21.09375% 0 0 0;
    margin: 0 auto;
    position: relative;
    width: 100%;
    max-width: 1920px;

    @media (min-width: 1600px) {
      padding: 0;
      height: 405px;
    }

    .slide {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      -webkit-backface-visibility: hidden;
    }

    .slick-list {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .slick-track {
      height: 100%;
    }
  }

}
