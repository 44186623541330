@use 'sass:math';

figure {
  margin: $spacer-2 0 $spacer-4 0;
  display: flex;
  flex-direction: column;

  &.img-center {
    margin: $spacer-6 auto;
    max-width: px2rem(800); /* 50rem */
    clear: both;
  }

  &.img-left {
    float: left;
    margin-right: $spacer-4;
  }

  &.img-right {
    float: right;
    margin-left: $spacer-4;
  }

  &.img-right, &.img-left {
    width: 100%;
    clear: both;

    @include media-breakpoint-up(sm) {
      //max-width: math.div(100%, 2);
    }

    @include media-breakpoint-up(md) {
      max-width: math.div(100%, 2);
    }

    @include media-breakpoint-up(lg) {
      max-width: math.div(100%, 2);
    }

    @include media-breakpoint-up(xl) {
      max-width: math.div(100%, 3);
    }
  }


  img {
    width: 100%;
    height: auto;
    display: block;
    margin: 0;
    padding: 0;
  }

  .fig-zoom {
    margin: 0;
    position: relative;
    cursor: pointer;

    &::after {
      @extend %icon-content;
      content: map-get($icon-map, 'zooming');
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-red;
      font-size: 1.6em;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, .9);
      width: 2.2em;
      height: 2.2em;
      position: absolute;
      bottom: .6em;
      right: .6em;
      padding: 0;
      z-index: 4;
      box-shadow: 0 .35rem .85rem rgba(0, 0, 0, 0.15);
      pointer-events: none;
    }
  }

  figcaption {
    font-size: 85%;
    font-weight: $font-weight-bold;
    padding: $spacer-3;
    color: $body-color;
    background-color: $color-gray-light;

    &:empty {
      display: none;
    }

    cite, .source {
      display: block;
      font-size: 80%;
      font-weight: $font-weight-normal;
    }
  }

}

//=== LIGHTGALLERY
[data-lightbox-gallery] figure {
  cursor: pointer;
}

//=== CLICKABLE ZOOMABLE IMAGE
//.fig-zoom {
//  cursor: pointer;
//  display: block;
//  margin-bottom: $spacer-4;
//  text-decoration: none;
//
//  figure {
//    display: flex;
//    flex-direction: column;
//    background-color: $color-gray-light;
//    position: relative;
//
//    &.nocaption::after {
//      @extend %icon-content;
//      content: map-get($icon-map, 'zooming');
//
//      color: $color-red;
//      font-size: 1.9rem;
//      text-align: center;
//      vertical-align: middle;
//      border-radius: 50%;
//      background-color: rgba(255, 255, 255, .9);
//      width: 3.7rem;
//      height: 3.7rem;
//      line-height: 2;
//      position: absolute;
//      bottom: 0;
//      right: $spacer-3;
//      transform: translateY(-$spacer-3);
//      padding: 0;
//      margin: 0;
//    }
//  }
//
//  figcaption {
//    color: $color-black;
//    font-weight: $font-weight-bold;
//    padding: $spacer-3;
//    position: relative;
//
//    &:empty {
//      display: none;
//    }
//
//    &::after {
//      @extend %icon-content;
//      content: map-get($icon-map, 'zooming');
//
//      color: $color-red;
//      font-size: 1.9rem;
//      text-align: center;
//      vertical-align: middle;
//      border-radius: 50%;
//      background-color: rgba(255, 255, 255, .9);
//      width: 3.7rem;
//      height: 3.7rem;
//      line-height: 2;
//      position: absolute;
//      bottom: 100%;
//      right: $spacer-3;
//      padding: 0;
//      transform: translateY(-$spacer-3);
//      box-shadow: 0 .35rem .85rem rgba(0, 0, 0, 0.15);
//    }
//  }
//
//  cite {
//    display: block;
//    color: $color-gray-base;
//    font-size: 0.83rem;
//    font-weight: normal;
//  }
//}

/* default Fraktionsfarben */
.-party-bordered {
  border-left: 6px solid;
}


.product-image-box {
  padding-top: 100%;
  background: white;
  position: relative;

  .product-image-box-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 8%;
    background-color: $color-gray-light;

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      
      > img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        box-shadow: 0 2px 4px 0 $color-gray-medium, 0 3px 10px 0 $color-gray-medium;
        min-height: 1px;
        min-width: 1px;
      }
    }
  }
}

.pi-box {
  padding-top: 100%;
  background: white;
  position: relative;

  .pi-box-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-gray-light;
    display: table-cell;
    text-align: center;
    vertical-align: middle;

    > img {
      max-width: 84%;
      max-height: 84%;
      display: block;
      position: absolute;
      top:50%;
      left: 50%;
      transform: translate(-50%,-50%);
      box-shadow: 0 2px 4px 0 $color-gray-medium, 0 3px 10px 0 $color-gray-medium;
      vertical-align: center;
    }
  }
}
