@use 'sass:math';

/* Glossar */
.jumplist {
  ol {
    display: flex;
    list-style: none;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: $spacer-5 * 2;
    margin-bottom: $spacer-5;

    // IE 11 Fix
    li {
      margin: 0.42rem;

      &:last-child {
        margin-right: 0;
      }
    }

    a {
      display: block;
      border: 2px solid $color-gray-medium;
      border-radius: 50%;
      color: $color-gray-medium;
      width: 2rem;
      height: 2rem;
      text-align: center;
      font-size: 1.2rem;
      font-weight: bold;
      text-decoration: none;
      transition: all $link-transition-duration $link-transition-type;

      &[href^='#'] {
        border: 2px solid $color-red;
        background-color: $color-red;
        color: $color-white;

        &:hover, &:active, &:focus {
          background-color: darken($link-color, 10%);
          border-color: darken($link-color, 10%);
        }
      }
    }
  }

  section {
    display: flex;
    flex-flow: column nowrap;
    margin: 2rem 0;
    border-top: 1px solid $color-gray-dark;

    > h2 {
      user-select: none;
      margin: 0 auto;
      position: sticky;
      top: 0;
      color: $color-white;
      background-color: $color-gray-dark;
      text-align: center;
      font-family: $font-family-serif;
      font-weight: 100;
      line-height: 1.6;
      width: 3rem;
      height: 3rem;
      font-size: 2rem;
      flex: 0 0 3rem;
      z-index: 6;
    }
  }

  dl {
    margin: 0;
  }

  dt {
    @extend %heading-h4;
    padding-top: $spacer-5;
  }

  dd {
    margin: 1rem 0 1rem 0;
  }

  dd + dt {
    border-top: 1px solid $color-gray-light;
  }

  @include media-breakpoint-up(lg) {
    section {
      flex-flow: row nowrap;

      > h2 {
        margin: 0 $spacer-4 0 0;
        width: 5rem;
        height: 5rem;
        font-size: 3rem;
        flex: 0 0 5rem;
      }
    }
  }
}

/* Abgeordnete */
.jumplist.-abgeordnete {

  /* Portraitansicht */
  section > div {
    display: flex;
    flex-flow: row wrap;
    flex: 1;
    margin-left: math.div($spacer-4, 2) * -1;
    margin-right: math.div($spacer-4, 2) * -1;
  }

  .jumplist-item {
    padding: $spacer-5 math.div($spacer-4, 2) 0 math.div($spacer-4, 2);
    display: inline-block;
    text-decoration: none;
    color: $color-black;
    transition: color $link-transition-duration $link-transition-type;
    width: 50%;

    @include media-breakpoint-up(sm) {
      width: 33%;
    }
    @include media-breakpoint-up(md) {
      width: 25%;
    }
    @include media-breakpoint-up(lg) {
      width: 33%;
    }
    @include media-breakpoint-up(xxl) {
      width: 25%;
    }

    figure {
      margin: 0;

      div {
        overflow: hidden;
        position: relative;
        margin-bottom: $spacer-2;

        img {
          display: block;
          height: auto;
          width: 100%;
          transition: all $link-transition-duration $link-transition-type;
        }

        &:after {
          height: 4px;
          width: 100%;
          background-color: $primary;
          display: block;
          content: '';
          position: absolute;
          z-index: 4;
          bottom: 0;
          transform: translateY(100%);
          transition: all $link-transition-duration $link-transition-type;
        }
      }

      figcaption {
        padding: 0;
        background-color: transparent;

        span {
          color: $color-gray-medium;
          font-weight: $font-weight-normal;
        }
      }
    }

    &:hover, &:focus, &:active {
      color: $primary;

      figure div {
        img {
          transform: scale(1.04);
        }

        &:after {
          transform: translateY(0%);
        }
      }

      figcaption {
        h6, span {
          color: $primary;
        }
      }
    }

  }

  /* Listenansicht */
  .jumplist-table {
    margin-left: 0;
    margin-right: 0;
    padding: $spacer-4 0 0 0;

  }
}

/* Bürgerbüros */
.jumplist.-buergerbueros {
  ul {
    width: 100%;
  }
}
