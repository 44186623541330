//=== Twitter Widget

.twitter-proxy {
  position: relative;

  ul {
    @extend %list-unstyled;
  }

  li {
    text-indent: initial !important;
    padding-left: 0 !important;
    list-style-position: unset !important;
    list-style-type: unset !important;
  }

  .simple-bar {
    /*overflow: auto;*/
    max-height: 460px;
    height: 100%;
  }

  strong {
    color: black;
  }

  .meta-info {
    color: $gray-400;
  }

  .tweet {
    margin-top: 1em;
    margin-right: 1em;
    hyphens: auto;
    word-break: break-word;
  }

  &::after {
    content: '';
    display: block;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
    height: 1.2em;
    position: absolute;
    bottom: 0;
    z-index: 0;
    width: 100%;
    pointer-events: none;
  }

  .simplebar-scrollbar:before {
    background: $color-red;
    opacity: 1;
  }

  .simplebar-track .simplebar-scrollbar.simplebar-visible:before {
    opacity: 1;
  }
}

.twitter-proxy-wrapper {
  h3 {
    a {
      color: $color-red;
    }

    i.fa {
      color: $color-red;
      background-color: transparent;
      margin-right: .25em;
      padding: 0;
      font-size: 20px;
    }
  }
}
