//=== BUTTON STYLES
a.btn {
  text-decoration: none;
}

.btn {
  border-radius: 0;
  text-align: center;
  appearance: none;
}

@include media-breakpoint-down(xs){
  .btn-xs-block {
    display: block;
    width: 100%;
  }
  input[type="submit"].btn-xs-block,
  input[type="reset"].btn-xs-block,
  input[type="button"].btn-xs-block {
    width: 100%;
  }
  .btn + .btn-xs-block,
  .btn + .btn-block{
    margin-top: $spacer-2;
  }
}

.btn-circle {
  border-radius: 50%;
  text-align: center;
}

.btn-secondary {
  color: $color-white;
}

