@use 'sass:math';

.multi-slides {
  .teaser {
    display: block;
    text-decoration: none;
    color: $color-black;
    transition: color $link-transition-duration $link-transition-type;

    .teaser-img {
      overflow: hidden;
      margin-bottom: $spacer-3;
      position: relative;

      &:after {
        height: 4px;
        width: 100%;
        background-color: $primary;
        display: block;
        content: '';
        position: absolute;
        z-index: 4;
        bottom: 0;
        transform: translateY(100%);
        transition: all $link-transition-duration $link-transition-type;
      }

      img {
        display: block;
        height: auto;
        width: 100%;
        transition: all $link-transition-duration $link-transition-type;
      }
    }

    .teaser-date {
      color: $color-gray-medium;
      transition: color $link-transition-duration $link-transition-type;
      margin: 0;
    }

    .teaser-title {
      font-size: px2rem(18);
      margin: .2rem 0 .75rem 0;
      transition: color $link-transition-duration $link-transition-type;
    }

    &:hover, &:focus, &:active {

      .teaser-img {
        img {
          transform: scale(1.04);
        }

        &:after {
          transform: translateY(0%);
        }
      }

      .teaser-title, .teaser-date, h6, h5 {
        color: $primary;
      }
    }
  }
}
