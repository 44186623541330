//=== ACCORDION

// todo implement accordion styles


.accordion-wrapper {
  border-bottom: 2px solid $color-gray-medium-light;
  padding: $spacer-3 0;
}

div[class*='__accordion-body '],
div[class$='__accordion-body']{
  height: 0;
  overflow: hidden;
  @media (prefers-reduced-motion: no-preference) {
    transition: height .5s ease-out;
  }

  &.show {
    height: auto;
    @media (prefers-reduced-motion: no-preference) {
      transition: height .5s ease-out;
    }
  }
}

div[class*='__accordion-body-wrapper'] {
  margin: $spacer-1 0 $spacer-2 0;
}

ul[class*='__accordion-body-wrapper'] > li {
  margin: $spacer-2 0;
}

a[class*="__accordion"] {

  text-decoration: none;

  @include media-breakpoint-up(sm) {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: baseline;
  }

  &[class*="collapsed"] {

    strong {
      &::before {
        border-bottom: solid .15em $color-black;
        transform: rotate(0deg);
      }
      &::after{
        transform: rotate(90deg);
      }
    }
  }

  strong {
    @extend %heading-h6;
    white-space: nowrap;
    display: block;
    margin: 0;
    padding-left: 1.2em;
    position: relative;

    &::before {
      content: '';
      display: block;
      width: .72em;
      height: 0;
      border-bottom: solid .15em transparent;
      border-radius: .075em;
      position: absolute;
      left: 0;
      top: .6em;
      transform: rotate(-90deg);

      @media (prefers-reduced-motion: no-preference) {
        transition: all .3s ease-in-out;
      }
    }
    &::after {
      content: '';
      display: block;
      width: .72em;
      height: 0;
      border-bottom: solid .15em $color-black;
      border-radius: .075em;
      position: absolute;
      left: 0;
      top: .6em;
      transform: rotate(0deg);

      @media (prefers-reduced-motion: no-preference) {
        transition: all .3s ease-in-out;
      }
    }

  }

  small {
    font-style: italic;
    //align-self: flex-end;
    white-space: nowrap;
    //line-height: 2.3;
    display: inline-block;
  }
}

div[class*="__accordion"] {
  h3, h4, h5, h6 {
    margin: 0;

    & > button {
      background-color: transparent;
      border: none;
      text-align: left;
      padding: 0 1.2em 0 0;
      margin: 0;
      width: 100%;
      position: relative;
      cursor: pointer;
      transition: all $link-transition-duration $link-transition-type;

      &:hover,&:focus,&:active {
        color: $primary;
      }

      &.collapsed {
        &::before {
          border-bottom: solid .15em $color-red;
          transform: rotate(0deg);
        }
        &::after{
          transform: rotate(90deg);
        }
      }

      &::before {
        content: '';
        display: block;
        width: 1em;
        height: 0;
        border-bottom: solid .15em transparent;
        border-radius: .075em;
        position: absolute;

        right: 0;
        top: .6em;

        transform: rotate(-90deg);
        @media (prefers-reduced-motion: no-preference) {
          transition: all .3s ease-in-out;
        }
      }
      &::after {
        content: '';
        display: block;
        width: 1em;
        height: 0;
        border-bottom: solid .15em $color-red;
        border-radius: .075em;
        position: absolute;

        right: 0;
        top: .6em;

        transform: rotate(0deg);
        @media (prefers-reduced-motion: no-preference) {
          transition: all .3s ease-in-out;
        }
      }
    }
  }
  
  h3 > button { @extend %heading-h3; }
  h4 > button { @extend %heading-h4; }
  h5 > button { @extend %heading-h5; }
  h6 > button { @extend %heading-h6; }
}

// special 

.box.accent {
  .accordion-wrapper {
    border-bottom: 2px solid $color-white;
    margin: $spacer-2 (-$spacer-4);
    padding: 0 $spacer-4 $spacer-2 $spacer-4;
  }
}