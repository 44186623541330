.deputy-profile {

  &:last-child {
    margin-bottom: $spacer-4;
  }

  h3 {
    margin-bottom: 1rem;
  }

  p[role=doc-subtitle] {
    margin-top: 0;
    font-size: larger;
  }

  ul.links {
    @extend %list-unstyled;
  }

}

@include media-breakpoint-up(md) {
  .deputy-profile {
    .fig-zoom {
      img {
        max-width: 300px;
      }
    }
  }
}
