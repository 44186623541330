$jump-width: 3rem;

.jump-up {
  position: absolute;
  right: 0;
  bottom: 0;
  @media (max-width: 356px) {
    bottom: 0;
  }

  width: auto;

  .btn-circle {
    margin: $spacer-2 $spacer-1;

    i {
      font-size: larger;
      transform: rotate(-90deg) translate(5px, 2px);
    }
  }
}

@include media-breakpoint-up($breakpoint-desktop) {
  .jump-up {
    position: static;
    text-align: right;
    bottom: unset;
    right: unset;
  }
}
