$icon-list-offset: 1.3rem;

//=== REGULAR LIST
ul {
  list-style-type: none;
  padding-left: $spacer-4;

  main & {
    display: table;
  }
  main table & {
    display: block;
  }

  li {
    &:before {
      content: '';
      display: inline-block;
      height: .625rem;
      width: .625rem;
      border: 2px solid $primary;
      border-radius: 100%;
      background-color: $primary;
      margin-left: ($spacer-4 *-1) + .4rem;
      margin-right: .875rem;
    }

    &:empty {
      &:before {
        background-color: transparent;
        border: none;
      }
    }

    li::before {
      background-color: transparent;
    }

    & + li {
      margin-top: $spacer-1;
    }
  }
}

.list-group-horizontal {
  li + li {
    margin-top: 0;
  }
}

//=== UNSTYLED LIST
%list-unstyled {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;

  li::before {
    content: none;
  }
}

.list-unstyled {
  @extend %list-unstyled;
}

// ohne border left, right (vgl. list-group-flush)
.list-group-ltbrb {
  @extend %list-unstyled;
  @include border-radius(0);

  > .list-group-item {
    border-width: $list-group-border-width 0 0;

    article > a {
      text-decoration: none;
    }

    &:last-child {
      border-bottom-width: $list-group-border-width;
    }
  }

  &.list-group-horizontal {
    li {
      border-bottom-width: $list-group-border-width;
    }
  }
}

.list-group-bordered {
  > :nth-child(n) {
    border-width: $list-group-border-width 0 0;

    &:last-child {
      border-bottom-width: $list-group-border-width;
    }
  }
}

.list-links {
  @extend %list-unstyled;

  &.--bordered {
    margin: $spacer-4 0;
    border-top: 1px solid $color-gray-medium-light;
    border-bottom: 1px solid $color-gray-medium-light;


    li {
      padding: $spacer-2 $spacer-3;
      border-top: 1px solid $color-gray-medium-light;
      transform: translateY(-1px);

     & + li {
        margin-top: 0;
      }
    }
  }

  li {

    .internal, .pdf, .download, .external, .video {
      display: inline-block;
      padding-left: 1.2em;

      &:before {
        width: 1.2em;
        margin-left: -1.2em;
        padding: 0;
      }

      .video-date {
        display: block;
        color: $gray-700;
      }
    }
  }
}


//=== ICON LIST
.list-icons {
  @extend %list-unstyled;

  li {
    position: relative;
    padding-left: $icon-list-offset;
    margin-bottom: $spacer-2;

    &, & > a {
      [class^='icon-']::before {
        position: absolute;
        top: .25em;
        left: 0;
      }
    }
  }
}

.list-entries {
  @extend %list-unstyled;
  margin-top: $spacer-4 !important;
  margin-bottom: $spacer-4 !important;
  display: block;

  .list-entry {
    position: relative;
    display: block;
    padding: $spacer-4 0;
    color: $body-color;

    @include media-breakpoint-up(sm) {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: flex-start;
    }

    &:after {
      content: '';
      height: 1px;
      width: 100%;
      background-color: $color-gray-medium-light;
      position: absolute;
      left: 0;
      bottom: 0;
      display: block;
      z-index: 4;
      transform-origin: top; // origin should be top because bottom will result in weird half-pixel-offset
      transform: scaleY(-1); // would flicker otherwise
      transition: all $link-transition-duration $link-transition-type;
    }
  }

  a.list-entry {
    text-decoration: none;

    * {
      transition: color $link-transition-duration $link-transition-type;
    }
  }

  a.list-entry:hover, a.list-entry:focus, a.list-entry:active {
    & * {
      color: $primary;
    }

    &:after {
      transform: scaleY(-4);
      background-color: $primary;
    }
  }

  .list-entry-text {
    color: $body-color;
    margin-left: 2rem;

    @include media-breakpoint-up(sm) {
      margin: 0;
    }

    p {
      margin-bottom: 0;
    }
  }

  .list-entry-title {
    @extend %heading-h5;
    color: $color-black;
    margin: 0;

    & + div {
      margin-top: $spacer-2;
    }
  }

  .list-entry-date {
    color: $color-gray-medium;
    position: relative;
    margin: 0 0 $spacer-1 0;
    padding-left: 2rem;

    @include media-breakpoint-up(sm) {
      flex: 0 0 9rem;
      margin: $spacer-1 $spacer-4 0 0;
    }

    &:before {
      @extend %icon;
      content: map-get($icon-map, 'calendar');
      font-size: 1.4em;
      position: absolute;
      left: 0;
      margin-top: -0.4rem;
      padding: 0;
    }
  }
}

.list-metainfo {
  @extend %list-unstyled;
  margin-top: $spacer-4 !important;
  margin-bottom: $spacer-2 !important;
  display: block;

  li + li {
    margin-top: 0;
  }

  .list-entry {
    position: relative;
    display: block;
    padding: $spacer-2 0;
    color: $body-color;
    border-top: 1px solid $color-gray-medium;

    @include media-breakpoint-up(sm) {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: flex-start;
    }

    &:last-child {
      border-bottom: 1px solid $color-gray-medium;
    }
  }

  .list-entry-text {
    color: $body-color;
    margin-left: 1.5rem;

    @include media-breakpoint-up(sm) {
      margin: 0;
    }

    p {
      margin-bottom: 0;
    }
  }

  .list-entry-date {
    position: relative;
    margin: 0 0 $spacer-1 0;
    padding-left: 1.5rem;
    font-weight: bold;

    @include media-breakpoint-up(sm) {
      flex: 0 0 8rem;
      margin: 0 $spacer-4 0 0;
    }

    &:before {
      @extend %icon;
      content: map-get($icon-map, 'calendar');
      position: absolute;
      left: 0;
      padding: 0;
    }
  }

  .list-entry-time {
    position: relative;
    margin: 0 0 $spacer-1 0;
    padding-left: 1.5rem;
    font-weight: bold;

    @include media-breakpoint-up(sm) {
      flex: 0 0 8rem;
      margin: 0 $spacer-4 0 0;
    }

    &:before {
      @extend %icon;
      content: map-get($icon-map, 'clock');
      position: absolute;
      left: 0;
      padding: 0;
    }
  }

  .list-entry-location {
    position: relative;
    margin: 0 0 $spacer-1 0;
    padding-left: 1.5rem;
    font-weight: bold;

    @include media-breakpoint-up(sm) {
      flex: 0 0 8rem;
      margin: 0 $spacer-4 0 0;
    }

    &:before {
      @extend %icon;
      content: map-get($icon-map, 'pointer');
      position: absolute;
      left: 0;
      padding: 0;
    }
  }

  .list-entry-download {
    position: relative;
    margin: 0 0 $spacer-1 0;
    padding-left: 1.5rem;
    font-weight: bold;

    @include media-breakpoint-up(sm) {
      flex: 0 0 8rem;
      margin: 0 $spacer-4 0 0;
    }

    &:before {
      @extend %icon;
      content: map-get($icon-map, 'download-2');
      position: absolute;
      left: 0;
      padding: 0;
    }
  }
  .list-entry-committee {
    position: relative;
    margin: 0 0 $spacer-1 0;
    padding-left: 1.5rem;
    font-weight: bold;

    @include media-breakpoint-up(sm) {
      flex: 0 0 8rem;
      margin: 0 $spacer-4 0 0;
    }

    &:before {
      @extend %icon;
      content: map-get($icon-map, 'notebook');
      position: absolute;
      left: 0;
      padding: 0;
    }
  }
}

@supports (display: grid) and (display: contents) {
  .list-metainfo {
    display: grid;
    grid-template-columns: auto 1fr;
    border-bottom: 1px solid #999;

    .list-entry {
      display: contents;

      & > div {
        border-top: 1px solid #999;
        padding-top: .75rem;
        padding-bottom: .75rem;
        padding-right: 1.875rem;
        margin-right: 0;
        margin-left: 0;
      }
    }
  }
}

// diary sessions
.events-legend {
  > li {
    &::before {
      content: "";
      display: inline-block;
      height: 1rem;
      width: 1rem;
      border-width: 3px;
      border-style: solid;
      border-radius: 100%;
      color: inherit;
      border-color: inherit;
      background-color: inherit;
      margin-left: -1.475rem;
      margin-right: 0.875rem;
    }
  }
}


//=== TABLE-INFO-LIST
.table {
  display: table;
  width: 100%;
  border-collapse: collapse;
}

.tr {
  display: table-row;
  width: 100%;
}

.td {
  display: table-cell;
}

.table-info {
  .td {
    border-top: 1px solid $color-black;
    padding: $spacer-2 0;
  }

  .tr:last-child {
    border-bottom: 1px solid $color-black;
  }
}


.sitemap {
  padding-left: 0;

  li {
    &:before {
      content: "";
      display: none;
    }

    a:not(.external) {
      @extend %icon;

      &::before {
        content: map-get($icon-map, 'right-arrow-1');
      }
    }
  }
  & > li {
    margin-bottom: $spacer-4;

    & > span {
      @extend %heading-h6;
      text-transform: uppercase;
    }

     & > ul{
       margin-top: $spacer-3;

      & > li {
        margin-bottom: $spacer-4;

        & > span {
          font-weight: 600;
        }

        ul {
          margin-top: $spacer-2;
          padding-left: 0;
        }
      }
    }

  }
}
