//=== TABLES

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

table {
  border-spacing: 0;
  width: 100%;

  thead tr th {
    border-bottom: 2px solid $primary;
    text-align: left;
    padding: $spacer-2 $spacer-3;
    vertical-align: top;
  }

  tbody tr td {
    border-bottom: 1px solid $color-gray-medium-light;
    padding: $spacer-2 $spacer-3;
    vertical-align: top;
  }

  tfoot tr td {
    border-bottom: 1px solid $color-black;
    padding: 0.6rem 1rem 0.2rem 1rem;
  }
}

/* responsive Tabelle, mit horizontaler Scrollbar */
.table-members {
  overflow-x: auto;

  table {
    min-width: 768px;
    table-layout: fixed;

    td:first-child {
      width: 75vw;
    }
  }
}
