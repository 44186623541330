//=== COOKIEBANNER

/* user ckb instead of cookiebanner prevent adblocker to remove banner */
.ckb {
  background-color: rgba(255, 255, 255, .95);
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  box-shadow: rgba(0, 0, 0, .2) 0 0 12px;
  max-height: 100%;
  overflow: auto;
  z-index: 1001; /* slider ueberdecken */
  font-size: px2rem(12);

  & > .container {
    padding: $spacer-2 $spacer-3;

    @include media-breakpoint-up(md) {
      padding: $spacer-3 $spacer-4;
    }
  }

  form {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 0 0 auto;
    margin: $spacer-2 0;
    font-size: $font-size-base;

    @include media-breakpoint-up(lg) {
      margin: $spacer-3 0;
      flex-flow: row wrap;
      align-items: center;
    }

    @include media-breakpoint-up(xxl) {
      margin: $spacer-3 0;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
    }

    .form-check {
      margin-right: $spacer-3;
      flex-shrink: 0;
    }
  }
  .ckb-buttons {
    width: 100%;

    @include media-breakpoint-up(sm) {
      width: auto;
    }

    @include media-breakpoint-up(lg) {
      width: 100%;
    }

    @include media-breakpoint-up(xxl) {
      margin-left: auto;
      width: auto;
    }


    .btn {
      display: block;
      width: 100%;
      margin: $spacer-2 0;
      font-size: px2rem(14);

      @include media-breakpoint-up(sm) {
        display: inline-block;
        width: auto;
        float: left;

        & + .btn {
          margin-left: $spacer-2;
        }
      }
    }

  }
  .ckb-navigation {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items: center;
  }

}

.ck-panel {
  h4 {
    margin-top: 0;
  }
  label {
    margin-bottom: 0;
  }
  .table tr td:first-child {
    width: 220px;
  }

}