@use 'sass:math';

*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  scroll-behavior: smooth;
}

@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

body {
  margin: 0;
  overflow-x: hidden;
  color: $body-color;
  //word-break: break-all;
  //word-break: break-word;
  word-wrap: break-word;

  @include media-breakpoint-up(md) {
    word-wrap: normal;
  }
}

.wrapper {
  background-color: $color-white;
  padding: 0.938rem;

  main {
    margin-bottom: $spacer-4;
  }
  aside {
    max-width: 100%;
    margin-top: $spacer-4;
  }
}

@include media-breakpoint-up(md) {
  .wrapper {
    //padding: 1.25rem;
  }
}

@include media-breakpoint-up(lg) {
  .wrapper {
    padding: $spacer-4;

    aside {
      margin-top: 0;
      max-width: 20rem;
      flex-basis: 20rem;
      font-size: .8rem;

      .box {
        padding: 1.25rem;

        address + address::before {
          margin: 1.25rem -1.25rem;
        }
      }
    }
  }

  header, footer {
    .wrapper {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .hide-on-desktop {
    display: none;
  }

  .hide-on-mobile {
    display: unset;
  }
}
@include media-breakpoint-up(xl) {
  .wrapper {
    aside {
      max-width: 22.5rem;
      flex-basis: 22.5rem;
      font-size: .875rem;

      .box {
        padding: $spacer-4;

        address + address::before {
          margin: $spacer-4 $spacer-4 * -1;
        }
      }
    }
  }
}

$link-color: $color-red;

::selection {
  color: $color-white;
  background-color: $color-gray-base;
}

// placeholder, that can be extended from various elements
// keep in mind, that selectors will be collected right here,
// like:
// blockquote, cite, .serif, ..., {...}
%serif-base {
  font-family: $font-family-serif;
  font-weight: $font-weight-base;
  font-style: normal;
}

.main {
  line-height: $line-height-main;
}

.hide-on-mobile {
  display: none;
}


//=== CONTAINER

.content {
  z-index: 1;
  background-color: $color-white;
  min-height: 30vmax;

  @include media-breakpoint-up(xl) {
    position: relative;
    margin-top: -2 * $spacer-5;
    display: flex;
    padding-left: $spacer-4;
    padding-right: $spacer-4;
    margin-left: auto;
    margin-right: auto;
  }
}

.text-large {
  font-size: $font-size-lg !important;
}

//special
a.text-black {
  text-decoration: none;
}

.text-black {
  color: $color-black !important;
}

.fw-light {
  font-weight: $font-weight-normal;
}

hr {
  background-color: $color-gray-medium-light;
  height: 2px;
  border: none;
  margin: $spacer-4 0;
  clear: both;
  float: none;
}

address {
  font-style: normal;
}

.vcard {
  .org, .organization-unit, .organization-name,
  .fn, .street-address, .tel, .email, .extended-address, .url {
    display: block;
  }

  .org {
    //margin-bottom: $spacer-1;
  }
}

address {
  p {
    margin: 0;

    + p {
      margin-top: 1rem;
    }

    &.email + .web {
      margin-top: 0;
    }
  }

  a {
    margin-top: $spacer-1;
  }
}

.lg-image {
  background-color: $white;
}

.logo-group {
  max-width: px2rem(220);
  margin-top: $spacer-2;
  margin-bottom: $spacer-2;
}

.text-red {
  color: $primary !important;
}


// print styles
@media print {
  .header, .footer, .breadcrumb {
    display: none;
  }

  .accent,
  .teaser-box.--gray,
  .product-image-box-inner,
  .btn-primary {
    background-color: transparent !important;
    border: 1px solid $gray-400;
  }

  .btn-primary {
    color: $gray-800 !important;
  }

  .ckb {
    border: 1px solid $gray-400;
  }
}

/*js3q ltbb watermark overrides*/
.sdn-branding {
  position: absolute !important;
  color: #000 !important;
  transition: .6s !important;
  display: flex !important;
  align-items: center !important;
  opacity: 1 !important;
  z-index: 1000 !important;
  padding: 0 !important;
  margin: 0 !important;
  width: 12% !important;
}
.sdn-branding.top-right {
  top: 2.5% !important;
  right: 1.5% !important;
  flex-direction: row-reverse !important;
}
.sdn-branding.top-left {
  top: 2.5% !important;
  left: 1.5% !important;
  flex-direction: row !important;
}
.sdn-branding.bottom-right {
  bottom: 2.5% !important;
  right: 1.5% !important;
  flex-direction: row-reverse !important;
}
.sdn-branding.bottom-left {
  bottom: 2.5% !important;
  left: 1.5% !important;
  flex-direction: row !important;
}
.sdn-player-controls[style*="display:none"] ~ .sdn-branding.bottom-left,
.sdn-player-controls[style*="display:none"] ~ .sdn-branding.bottom-right {
  bottom: 2.5% !important;
}
.sdn-display.controls .sdn-branding.bottom-right {
  bottom: 50px !important;
  right: 1.5% !important;
  flex-direction: row-reverse !important;
}
.sdn-display.controls .sdn-branding.bottom-left {
  bottom: 50px !important;
  left: 1.5% !important;
  flex-direction: row !important;
}

.sdn-branding .sdn-branding-texts{
  display: none !important;
}
.sdn-branding .sdn-branding-picture {
  margin: 0 !important;
  display: block !important;
  width:100% !important;
  height: auto !important;
}
.sdn-branding .sdn-branding-picture .sdn-branding-picture {
  display: block !important;
  width:100% !important;
  height: auto !important;
}

.sdn-branding {
  z-index: 1000 !important;
}

/* Fullscreen 16:9 */
.embed-responsive-16by9 .js3q-fullscreen {
  display: flex !important;
  flex-flow: row wrap !important;
  justify-content: center !important;
  align-items: center !important;
}
.embed-responsive-16by9 .js3q-fullscreen .js3q-player {
  position: relative !important;
  height: unset !important;
}
.embed-responsive-16by9 .js3q-fullscreen .js3q-player::before {
  content: "";
  display: block;
  padding-top: 56.25%;
}

.embed-responsive-16by9 .js3q-fullscreen .js3q-player .sdn-display {
  border: 0;
  bottom: 0 !important;
  height: 100% !important;
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
  width: 100% !important;
}

/*
 * Logo je Raum ID:  On Demand, Raum 1060, ViKo 1050, ViKo 1070, ViKo 2050, ViKo 3050
 */
:is([data-dataid="650c0a7e-50dd-11eb-b839-0cc47a188158"],
[data-dataid="db696edb-5cc4-11ec-99ef-3cecef385192"],
[data-dataid="3b422d46-8e0c-11ea-97a4-002590c750be"],
[data-dataid="a9f7d16d-8ad4-11ea-97a4-002590c750be"],
[data-dataid="0195178b-8e0d-11ea-97a4-002590c750be"],
[data-dataid="99a6e0cf-723d-11ed-bf6a-3cecef385192"]) .sdn-branding .sdn-branding-picture .sdn-branding-picture {
  opacity: 1 !important;
}
