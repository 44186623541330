.recording-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $gray-200;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    align-items: start;
  }

  &-start,
  &-stop {
    display: inline-flex;
    align-items: center;
    gap: .5em;
  }

  &-start::before,
  &-stop::before {
    display: inline-block;
    content: '';
  }

  &-start::before {
    border-left: .875em solid currentColor;
    border-top: .5em solid transparent;
    border-bottom: .5em solid transparent;
    height: 1em;
  }

  &-stop::before {
    background: currentColor;
    width: .875em;
    height: .875em;
  }

  &-state {
    @include media-breakpoint-down(sm) {
      align-self: stretch;
    }

    span,
    em,
    strong {
      display: block;
      padding: .85rem 1.1rem;
    }

    em,
    strong {
      color: $color-white;
      font-weight: normal;
      font-style: normal;
    }

    em {
      background: $gray-700;
    }

    strong {
      background: $color-red;
    }
  }
}
