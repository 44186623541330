// Fraktionsfarben - wird vom CMS überschrieben
// @deprecated Fraktionsfarben
// werden noch für Dev benutzt .... sind auch nur dummy Farben
$party-color-spd: rgb(227, 0, 15);
$party-color-cdu: rgb(83, 84, 94);
$party-color-gruene-b90: rgb(94, 128, 0);
$party-color-fdp: yellow;
$party-color-afd: rgb(118, 162, 221);
$party-color-linke: rgb(252, 175, 248);
$party-color-bvb-fw: rgb(148, 148, 2);

$white: #fff !default;
$gray-100: #f9f9f9;
$gray-200: #f2f2f2;
$gray-400: #ccc;
$gray-600: #999999;
$gray-700: #666;
$gray-800: #333;
$black: #000;


$color-gray-dark: $gray-800;
$color-gray-base: $gray-700;
$color-gray-medium: $gray-600;
$color-gray-medium-light: $gray-400;
$color-gray-light: $gray-200;
$color-gray-lighter: $gray-100;

$color-red: #c8002d;

$color-black: $black;
$color-white: $white;
