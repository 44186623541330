@use 'sass:math';

.teaser-grid {
  margin: $spacer-4 ($spacer-4 * -.5);
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: stretch;

  article {
    margin: 0;
    width: 100%;
    position: relative;
    padding: 0;
    transition: 0.4s;

    @include media-breakpoint-up(sm) {
      width: 50%;
    }
    @include media-breakpoint-up(xxl) {
      width: 33.3333333333%;
    }

    a {
      display: block;
      margin: $spacer-4 math.div($spacer-4, 2);
      text-decoration: none;
      color: $color-black;
      transition: color $link-transition-duration $link-transition-type;

      .teaser-img {
        overflow: hidden;
        margin-bottom: $spacer-3;
        position: relative;

        &:after {
          height: 4px;
          width: 100%;
          background-color: $primary;
          display: block;
          content: '';
          position: absolute;
          z-index: 4;
          bottom: 0;
          transform: translateY(100%);
          transition: all $link-transition-duration $link-transition-type;
        }

        img {
          display: block;
          height: auto;
          width: 100%;
          transition: all $link-transition-duration $link-transition-type;
        }

      }

      .teaser-date, .teaser-category {
        color: $color-gray-medium;
        transition: color $link-transition-duration $link-transition-type;
      }

      h5, h6 {
        @extend %heading-h6;
        margin: .2rem 0 .75rem 0;
        transition: color $link-transition-duration $link-transition-type;
      }

      &:hover, &:focus, &:active {
        //color: $primary;

        .teaser-img {
          img {
            transform: scale(1.04);
          }

          &:after {
            transform: translateY(0%);
          }
        }

        .teaser-title, .teaser-date, h6, h5 {
          color: $primary;
        }
      }
    }
  }

  &.-mediathek {
    article {
      &:hover {
        .teaser-date, .teaser-category {
          color: $primary;
        }
      }

      .teaser-date, .teaser-category {
        font-size: $font-size-sm;
      }
    }
  }
}

.teaser-list {
  article {
    position: relative;

    > a {
      color: $body-color;
      display: block;
      padding: .01rem 0;
      text-decoration: none;

      @include media-breakpoint-up(sm) {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0 ($spacer-4 * -.5);
        padding: 0;
      }

      & * {
        transition: color $link-transition-duration $link-transition-type;
      }

      .teaser-img {
        margin: $spacer-4 0;
        width: 100%;
        position: relative;

        @include media-breakpoint-up(sm) {
          width: 33.3333333333%;
          padding: 0 math.div($spacer-4, 2);

          .copyright-notice {
            left: math.div($spacer-4, 2);
            right: math.div($spacer-4, 2);
          }
        }

        img {
          max-width: 100%;
          height: auto;
          display: block;
        }

        &:before {
          bottom: 8%;
          right: 5%;

          @include media-breakpoint-up(sm) {
            right: calc(4% + #{math.div($spacer-4, 2)});
          }
        }

      }

      .teaser-text {
        margin: $spacer-4 0;
        width: 100%;

        @include media-breakpoint-up(sm) {
          width: 66.6666666667%;
          padding: 0 math.div($spacer-4, 2);
          flex: 1 0 auto;
        }

        .teaser-date {
          color: $color-gray-medium;
          margin-bottom: $spacer-1;
        }

        h5, .teaser-title {
          margin-top: 0;
        }
        p {
          margin-bottom: 0;
        }
      }

      &:hover, &:focus, &:active {
        h5, .teaser-title, .teaser-date {
          color: $primary !important;
        }
      }

    }

    &:after {
      content: '';
      height: 1px;
      width: 100%;
      background-color: $color-gray-medium-light;
      position: absolute;
      bottom: 0;
      display: block;
      z-index: 4;
      transition: all $link-transition-duration $link-transition-type;
    }

    &:hover, &:focus, &:active {
      &:after {
        height: 4px;
        background-color: $primary;
      }
    }

  }
}


.teaser-box {
  padding: math.div($spacer-4, 2);
  margin: $spacer-4 0;

  @include media-breakpoint-up(md) {
    padding: $spacer-4;
  }

  .row {
    align-items: flex-start;
  }

  &.--gray {
    background-color: $color-gray-light;
  }

  &.--bordered {
    border: 1px solid $color-gray-light;
  }

  &.--big {
    margin-left: math.div($spacer-4, -2);
    margin-right: math.div($spacer-4, -2);

    @include media-breakpoint-up(lg) {
      margin-left: ($spacer-4 * -1);
      margin-right: ($spacer-4 * -1);
    }
  }

  p:first-child {
    margin-top: 0;
  }

  .col-lg + .col-lg {
    margin-top: $spacer-4;

    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }

  .teaser-img {
    margin-bottom: $spacer-2;

    .copyright-notice {
      left: math.div($spacer-4, 2);
      right: math.div($spacer-4, 2);
    }

    @include media-breakpoint-up(md) {
      max-width: px2rem(440);
      margin-bottom: 0;
    }
  }

  h6 {
    margin-bottom: $spacer-2;
  }
}

.teaser-img {

  &.-signlanguage, &.-livestream  {
    &::before {
      @extend %icon-content;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-red;
      font-size: 1.4em;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, .9);
      width: 2em;
      height: 2em;
      position: absolute;
      bottom: 7%;
      right: 4%;
      padding: 0;
      z-index: 4;
      box-shadow: 0 .35rem .85rem rgba(0, 0, 0, 0.15);
    }
  }
  &.-signlanguage::before {
    content: map-get($icon-map, 'five');
  }
  &.-livestream::before {
      content: map-get($icon-map, 'video-camera');
  }
}

.teaser-img, .topnews-img, aside .box figure {
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
    display: block;
  }

  .copyright-notice {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: .75em;

    &:before {
      @extend %icon-content;
      content: map-get($icon-map, 'info');
      position: absolute;
      left: .65em;
      bottom: .5em;
      color: $color-white;
      transition: opacity $link-transition-duration $link-transition-type;
      text-shadow: 0 0 4px rgba($color-black,.4);
    }

    .source {
      background-color: rgba($color-white, .9);
      padding: .5em .75em .6em .75em;
      transform: translateY(100%);
      width: 100%;
      transition: all $link-transition-duration $link-transition-type;
    }

    &:hover {
      &:before {
        opacity: 0;
      }
      .source {
        transform: translateY(0);
      }
    }
  }
}
