@import 'lg-variables';
@import 'lg-mixins';
.lg-outer {
    .lg-img-rotate {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        @include transitionCustom(
            transform 0.4s cubic-bezier(0, 0, 0.25, 1) 0s
        );
    }
    &[data-lg-slide-type='video'],
    &[data-lg-slide-type='iframe'] {
        .lg-rotate-left,
        .lg-rotate-right,
        .lg-flip-ver,
        .lg-flip-hor {
            opacity: $lg-toolbar-icon-disabled-opacity;
            pointer-events: none;
        }
    }
    .lg-img-rotate {
        &:before {
            content: '';
            display: inline-block;
            height: 100%;
            vertical-align: middle;
        }
    }
}
.lg-rotate-left {
    &:after {
        content: '\e900';
    }
}
.lg-rotate-right {
    &:after {
        content: '\e901';
    }
}
.lg-icon {
    &.lg-flip-hor,
    &.lg-flip-ver {
        font-size: 26px;
    }
}
.lg-flip-ver {
    &:after {
        content: '\e903';
    }
}
.lg-flip-hor {
    &:after {
        content: '\e902';
    }
}
