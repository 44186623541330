@use 'sass:math';

$negative-figure-spacer: $spacer-2 * -1;

.box {
  padding: $spacer-3;
  margin-bottom: $spacer-4;

  @include media-breakpoint-up(md) {
    padding: $spacer-4;
  }

  h4, h5, h6 {
    margin-bottom: $spacer-2;
  }

  img {
    max-width: 100%;
  }

  address {
    position: relative;

    + address {
      &:before {
        content: '';
        display: block;
        height: 1px;
        background-color: $color-gray-medium-light;
        position: relative;
        margin: $spacer-3 $spacer-3 * -1;

        @include media-breakpoint-up(md) {
          margin: $spacer-4 $spacer-4 * -1;
        }

      }
    }
  }

  &.bordered {
    border: 1px solid $color-gray-medium-light;
  }

  &.shadow {
    box-shadow: 0 math.div($spacer-1, 2) $spacer-1 $spacer-1 $color-gray-medium-light;
  }

  &.accent {
    background-color: $color-gray-lighter;
  }
}

@include media-breakpoint-up($breakpoint-desktop) {
  .box {
    figure:first-child {
      margin: $negative-figure-spacer $negative-figure-spacer 0 $negative-figure-spacer;
    }
  }
}

.box-notice {
  position: relative;
  border: 1px solid $color-gray-medium-light;
  padding: $spacer-3 $spacer-5;
  margin: $spacer-4 0;

  .icon-info {
    position: absolute;
    display: inline-block;
    transform: translateX(-105%);
  }

  p {
    margin: 0;

    & + p {
      margin-top: $paragraph-margin-bottom;
    }
  }
}

.box-cac {
  @extend .shadow;
  padding: $spacer-4 $spacer-6+$spacer-4 $spacer-4 $spacer-4;
  margin: $spacer-4 0;
  position: relative;
  border-left: 6px solid $color-gray-medium;
  @include media-breakpoint-up(sm) {
    padding: $spacer-4 2*$spacer-6+$spacer-4 $spacer-4 $spacer-4;
  }

  h5 {
    margin-bottom: $spacer-2;
  }
  .group-logo{
    position: absolute;
    max-width: calc($spacer-6 - $spacer-1);
    top: $spacer-4;
    right: $spacer-4;
    bottom: $spacer-4;
    text-align: right;

    @include media-breakpoint-up(sm) {
      max-width: calc(2*$spacer-6 - $spacer-1);
    }

    img{
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      object-position: top right;
    }
  }
  address + address{
    margin-top: $spacer-3;
  }

  > .row > [class*="col-"] + [class*="col-"] {
    margin-top: $spacer-3;

    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
    @include media-breakpoint-up(lg) {
      margin-top: $spacer-3;
    }
    @include media-breakpoint-up(xl) {
      margin-top: 0;
    }
  }
}

.address-boxes {
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  justify-content: space-between;

  address.vcard {
    box-shadow: 0 .35rem .85rem rgba(0, 0, 0, 0.15);
    padding: $spacer-4;
    margin: math.div($spacer-4, 2) 0;
    flex: 1 1 100%;

    @include media-breakpoint-up(md) {
      flex: 1 1 calc(50% - math.div($spacer-4, 2));
      max-width: calc(50% - math.div($spacer-4, 2));
    }
    @include media-breakpoint-up(lg) {
      flex: 1 1 100%;
      max-width: 100%;
    }
    @include media-breakpoint-up(xl) {
      flex: 1 1 calc(50% - math.div($spacer-4, 2));
      max-width: calc(50% - math.div($spacer-4, 2));
    }

  }
}

.profile-boxes {
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  justify-content: space-between;

  .profile-box {
    box-shadow: 0 .35rem .85rem rgba(0, 0, 0, 0.15);
    margin: math.div($spacer-4, 4) 0 ;
    flex: 1 1 100%;
    border-left: 4px solid $color-gray-medium;

    @include media-breakpoint-up(md) {
      flex: 1 1 calc(50% - math.div($spacer-4, 4));
      max-width: calc(50% - math.div($spacer-4, 4));
      margin: math.div($spacer-4, 2) 0 ;
    }
    @include media-breakpoint-up(lg) {
      flex: 1 1 100%;
      max-width: 100%;
    }
    @include media-breakpoint-up(xxl) {
      flex: 1 1 calc(50% - math.div($spacer-4, 2));
      max-width: calc(50% - math.div($spacer-4, 2));
    }

    figure {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      margin: 0;

      img {
        width: px2rem(120);
        height: 100%;
        align-self: flex-start;
        background-color: $color-gray-light;
        object-fit: contain;
        flex-shrink: 0;
      }

      figcaption {
        align-self: stretch;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        padding: $spacer-2 $spacer-3;
        background-color: transparent;

        @include media-breakpoint-up(sm) {
          padding: $spacer-3 $spacer-4;
        }
        @include media-breakpoint-up(md) {
          padding: $spacer-2 $spacer-3;
        }
        @include media-breakpoint-up(lg) {
          padding: $spacer-3 $spacer-4;
        }

        h6 {
          @extend %heading-h5;
          color: inherit;
          margin: 0;
          line-height: 1.2;
        }
        a.profile{
          margin-top: auto;
          white-space: nowrap;
        }
      }
    }
  }
}
