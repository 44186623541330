// custom initial variables includes bootstrap overwrite
@import 'style/colors';

@import 'style/functions';
@import 'style/variables';

// bootstrap
@import 'style/vendor/custom.bootstrap';

//Simplebar
@import "../node_modules/simplebar/dist/simplebar.css";

// custom

@import 'style/reset';

@import 'style/base';
@import 'style/utilites';
@import 'style/type';

@import 'style/accordion';
@import 'style/badge';
@import 'style/basket';
@import 'style/box';
@import 'style/buttons';
@import 'style/breadcrumb';
@import 'style/cookiebanner';
@import 'style/deputy-profile';
@import 'style/diary';
@import 'style/footer';
@import 'style/forms';
@import 'style/gallery';
@import 'style/header';
@import 'style/highlight-box';
@import 'style/icon';
@import 'style/image';
@import 'style/jump-up';
@import 'style/jumplist';
@import 'style/lists';
@import 'style/teaser';
@import 'style/tables';
@import 'style/twitter';
@import 'style/pagination';
@import 'style/autocomplete';
@import 'style/widget-list';
@import 'style/slides';
@import 'style/swap-on-collapsed';
@import 'style/recording-controls';

// page
@import 'style/page/wahlkreiskarte';
@import 'style/page/livestream';
@import 'style/page/plenarsaal';
@import 'style/page/abgeordnete';

// vendor
@import 'style/vendor/slick-carousel';
