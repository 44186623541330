//=== FONTFACE
/* montserrat-regular - latin-ext_latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../static/fonts/montserrat-v18-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../static/fonts/montserrat-v18-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../static/fonts/montserrat-v18-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../static/fonts/montserrat-v18-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../static/fonts/montserrat-v18-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../static/fonts/montserrat-v18-latin-ext_latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-italic - latin-ext_latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('../static/fonts/montserrat-v18-latin-ext_latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../static/fonts/montserrat-v18-latin-ext_latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../static/fonts/montserrat-v18-latin-ext_latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../static/fonts/montserrat-v18-latin-ext_latin-italic.woff') format('woff'), /* Modern Browsers */
  url('../static/fonts/montserrat-v18-latin-ext_latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../static/fonts/montserrat-v18-latin-ext_latin-italic.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-600 - latin-ext_latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../static/fonts/montserrat-v18-latin-ext_latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../static/fonts/montserrat-v18-latin-ext_latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../static/fonts/montserrat-v18-latin-ext_latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('../static/fonts/montserrat-v18-latin-ext_latin-600.woff') format('woff'), /* Modern Browsers */
  url('../static/fonts/montserrat-v18-latin-ext_latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../static/fonts/montserrat-v18-latin-ext_latin-600.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-600italic - latin-ext_latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url('../static/fonts/montserrat-v18-latin-ext_latin-600italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../static/fonts/montserrat-v18-latin-ext_latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../static/fonts/montserrat-v18-latin-ext_latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../static/fonts/montserrat-v18-latin-ext_latin-600italic.woff') format('woff'), /* Modern Browsers */
  url('../static/fonts/montserrat-v18-latin-ext_latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../static/fonts/montserrat-v18-latin-ext_latin-600italic.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* frank-ruhl-libre-regular - latin-ext_latin */
@font-face {
  font-family: 'Frank Ruhl Libre';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../static/fonts/frank-ruhl-libre-v6-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../static/fonts/frank-ruhl-libre-v6-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../static/fonts/frank-ruhl-libre-v6-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../static/fonts/frank-ruhl-libre-v6-latin-ext_latin-regular.woff') format('woff'); /* Modern Browsers */
}

/* flaticons */
@font-face {
  font-family: Icons;
  font-display: swap;
  src: url('../static/fonts/flaticon.ttf?e59cb32a3db16726a82c66aa248eb515') format('truetype'),
  url('../static/fonts/flaticon.woff?e59cb32a3db16726a82c66aa248eb515') format('woff'),
  url('../static/fonts/flaticon.woff2?e59cb32a3db16726a82c66aa248eb515') format('woff2'),
  url('../static/fonts/flaticon.eot?e59cb32a3db16726a82c66aa248eb515#iefix') format('embedded-opentype'),
  url('../static/fonts/flaticon.svg?e59cb32a3db16726a82c66aa248eb515#flaticon') format('svg');
}

body {
  //font-size: $font-size-base;
  font-size: px2rem(16);
  line-height: $line-height-base;
  font-family: $font-family-sans;
  font-weight: $font-weight-base;
  color: $body-color;
}

//=== HEADINGS

$line-height-heading: 1.3;

%heading {
  font-family: $font-family-sans;
  line-height: $line-height-heading;
  font-weight: $font-weight-bold;
  margin-top: 1.3em;
  margin-bottom: .125em;
  hyphens: auto;
  color: $color-black;

  @include media-breakpoint-up(xxl) {
    hyphens: none;
  }


}

%heading-h1 {
  @extend %heading;
  font-size: $font-size-h1;
  @media (min-width: 480px) { font-size: px2rem(30); }
  @include media-breakpoint-up(md) { font-size: px2rem(34); }
  @include media-breakpoint-up(lg) { font-size: px2rem(36); }
  @include media-breakpoint-up(xl) { font-size: px2rem(38); }
}

%heading-h2 {
  @extend %heading;
  font-size: $font-size-h2;
  @media (min-width: 480px) { font-size: px2rem(28); }
  @include media-breakpoint-up(md) { font-size: px2rem(32); }
  @include media-breakpoint-up(lg) { font-size: px2rem(34); }
  @include media-breakpoint-up(xl) { font-size: px2rem(36); }
}

%heading-h3 {
  @extend %heading;
  font-size: $font-size-h3;
  @media (min-width: 480px) { font-size: px2rem(24); }
  @include media-breakpoint-up(md) { font-size: px2rem(28); }
  @include media-breakpoint-up(lg) { font-size: px2rem(30); }
  @include media-breakpoint-up(xl) { font-size: px2rem(32); }
}

%heading-h4 {
  @extend %heading;
  font-size: $font-size-h4;
  @media (min-width: 480px) { font-size: px2rem(22); }
  @include media-breakpoint-up(md) { font-size: px2rem(26); }
  @include media-breakpoint-up(lg) { font-size: px2rem(28); }
  @include media-breakpoint-up(xl) { font-size: px2rem(30); }
}

%heading-h5 {
  @extend %heading;
  font-size: $font-size-h5;
  @media (min-width: 480px) { font-size: px2rem(20); }
  @include media-breakpoint-up(md) { font-size: px2rem(22); }
  @include media-breakpoint-up(lg) { font-size: px2rem(25); }
  @include media-breakpoint-up(xl) { font-size: px2rem(28); }
}

%heading-h6 {
  @extend %heading;
  font-size: $font-size-h6;
  @media (min-width: 480px) { font-size: px2rem(18); }
  @include media-breakpoint-up(md) { font-size: px2rem(20); }
  @include media-breakpoint-up(lg) { font-size: px2rem(21); }
  @include media-breakpoint-up(xl) { font-size: px2rem(22); }
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {

  &:first-child {
    margin-top: 0;
  }
}

h1, h2, h3, h4, h5, h6{
  &.subtitle,
  &.popover-header,
  + h1, + h2, + h3, + h4, + h5, + h6 {
    margin-top: 0 !important;
  }
}

nav {
  + h1, + .h1,
  + h2, + .h2,
  + h3, + .h3,
  + h4, + .h4,
  + h5, + .h5,
  + h6, + .h6 {
    margin-top: 0 !important;
  }
}

h1, .h1 {
  @extend %heading-h1;
}

h2, .h2 {
  @extend %heading-h2;
}

h3, .h3 {
  @extend %heading-h3;
}

h4, .h4 {
  @extend %heading-h4;
}

h5, .h5 {
  @extend %heading-h5;
}

h6, .h6 {
  @extend %heading-h6;
}

aside {
  font-size: px2rem(15);

  h4 {
    font-size: px2rem(22);
  }
}

@media (min-width: 480px) {
  body {
    font-size: px2rem(15);
  }
  aside {
    font-size: px2rem(15);

    h4 {
      font-size: px2rem(18);
    }
  }
}

@include media-breakpoint-up(md) {
  body {
    font-size: px2rem(16);
  }

  aside {
    font-size: px2rem(15);

    h4 {
      font-size: px2rem(20);
    }
  }
}

@include media-breakpoint-up(lg) {
  body {
    font-size: px2rem(16);
  }

  aside {
    font-size: px2rem(14);

    h4 {
      font-size: px2rem(22);
    }
  }
}

@include media-breakpoint-up(xl) {
  body {
    font-size: px2rem(17);
  }

  aside {
    font-size: px2rem(15);

    h4 {
      font-size: px2rem(22);
    }
  }
}

//=== LINKS

a {
  color: $link-color;
  text-decoration: none;
  transition: color $link-transition-duration $link-transition-type;

  &:hover, &:active, &:focus {
    color: darken($link-color, 10%);
  }

  &[href^="tel:"] {
    color: inherit;
    text-decoration: none;
  }

}

main a, aside a {
  text-decoration: underline;

  &:hover, &:active, &:focus {
    text-decoration: none;
  }

}



//=== PARAGRAPH

// todo implement paragraph styles
p {
}

b, strong {
  font-weight: $font-weight-bold;
}

em {
}

small {
}

//=== QUOTATIONS


blockquote {
  @extend %serif-base;
  font-family: $font-family-serif;
  margin: $spacer-4 0;
  font-size: $font-size-h6-desktop;
  line-height: 1.3;

  &::after {
    font-family: $font-family-sans;
    font-weight: $font-weight-bold;
    font-size: px2rem(14);
    content: attr(cite);
    color: $color-gray-medium;
    display: block;
    border-top: px2rem(1) solid $body-color;
    margin-top: $spacer-3;
    padding-top: $spacer-3;
  }
}

q {
  @extend %serif-base;
  display: inline-block;
  margin-top: $spacer-4;
  margin-bottom: $spacer-4;
}

.serif {
  @extend %serif-base;
}
