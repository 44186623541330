%icon-content {
  display: inline-block;
  //noinspection CssNoGenericFontName
  font-family: Icons;
  font-size: 1em;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: inherit;
  //vertical-align: baseline;
  vertical-align: top;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-right: 0.3rem;
}

%icon {
  text-decoration: none;

  &::before {
    @extend %icon-content;
  }
}

// https://stackoverflow.com/a/13352103/270935
[class^='icon-'], [class*=' icon-'] {
  @extend %icon
}

// generate all icons from list
@each $name, $unicode in $icon-map {
  .icon-#{$name}::before {
    content: $unicode
  }
}

.icon-next {
  &:before {
    content: map-get($icon-map, 'chevron-right');
  }
}

.icon-prev {
  &:before {
    content: map-get($icon-map, 'chevron-left');
  }
}

.icon-up {
  &:before {
    content: map-get($icon-map, 'chevron-up');
  }
}

.icon-down {
  &:before {
    content: map-get($icon-map, 'chevron-down');
  }
}

.icon-cart{
  &:before {
    padding-right: .5rem;
  }
}

a.email, a[href^="mailto:"] {
  @extend %icon;

  &::before {
    content: map-get($icon-map, 'envelope');
  }
}


// special links
a.download,
a.document {
  @extend %icon;

  em {
    font-style: normal;
    font-size: 80%;
    color: $body-color;
  }

  &::before {
    content: map-get($icon-map, 'document');
  }

  &[href$='.pdf'] {
    content: map-get($icon-map, 'pdf');
  }
}

a.pdf,a[href$='.pdf'] {
  @extend %icon;

  em {
    font-style: normal;
    font-size: 80%;
    color: $body-color;
  }

  &::before {

    content: map-get($icon-map, 'pdf');
  }
}

a.video {
  @extend %icon;

  &::before {
    content: map-get($icon-map, 'video-camera');
  }
}

a.internal {
  @extend %icon;

  &::before {
    content: map-get($icon-map, 'right-arrow-1');
  }
}

a.external {
  @extend %icon;

  &::before {
    content: map-get($icon-map, 'link');
    vertical-align: baseline;
  }

  &[href$='.pdf'] {
    &::before {
      content: map-get($icon-map, 'pdf');
    }
  }
}

a.profile {
  @extend %icon;

  &::before {
    content: map-get($icon-map, 'user');
  }
}

a.info, .info{
  @extend %icon;

  &::before {
    content: map-get($icon-map, 'info');
  }
}

a.rss {
  @extend %icon;

  &::before {
    content: map-get($icon-map, 'rss');
  }
}

a.calendar {
  @extend %icon;

  &::before {
    content: map-get($icon-map, 'calendar');
  }
}

a.addcal, a.ics, .icon-add {
  @extend %icon;

  &::before {
    content: map-get($icon-map, 'calendar-4');
  }
}

a.location {
  @extend %icon;

  &::before {
    content: map-get($icon-map, 'pointer');
  }
}

a.view {
  @extend %icon;

  &::before {
    content: map-get($icon-map, 'view');
  }
}

.press {
  @extend %icon;

  &::before {
    content: map-get($icon-map, 'file');
  }
}

.icon-burger {
  display: inline-block;
  width: 2rem;
  margin-left: 0.5rem;
  vertical-align: middle;

  span {
    display: block;
    border-top: 2px solid $primary;
    margin-bottom: 2px;
  }
}

.delete {
  @extend %icon;

  &::before {
    content: map-get($icon-map, 'bin');
  }
}

.reload {
  @extend %icon;

  &::before {
    content: map-get($icon-map, 'reload');
  }
}

.icon-portrait {
  display: inline-block;
  margin-left: 0.5rem;
  vertical-align: middle;

  & > span {
    display: block;
    line-height: 0;
  }

  & > span > span {
    display: inline-block;
    border: 1px solid $primary;
    height: 10px;
    width: 10px;
    margin: 0 2px 2px 0;
  }
}

.footer > .container .follow-us {
  h6 {
    margin-right: .5rem;
  }

  .ico-twitter, .ico-instagram {
    svg {
      width: px2rem(35);
    }
  }
}

.footer > .container .follow-us svg {
  max-width: 3rem;
  width: 3rem;
  height: 3rem;
  margin-right: 2rem;
}

.footer > .container .follow-us a:last-child {
  svg {
    margin-right: 0;
  }
}
