@use 'sass:math';

/* Plenarsaal */
.lt-plenarsaal {
  position: relative;
  margin: 0 -15px;
}

.lt-plenarsaal__sitze {
  width: 100%;
  padding: 10px;
  margin-bottom: 40px;
}

.lt-plenarsaal__pulte {
  display: block;
  margin: 0 auto -30px auto;
  width: 80%;
}

.lt-plenarsaal__image {
  margin: 15px 15px 0 0;
  overflow: visible !important;
  width: 100%;
}

/* Overlay */
.lt-plenarsaal__overlay {
  margin: 15px 0;
  width: 100%;
  box-shadow: 0 1px 4px $color-gray-medium-light;
  background-color: $color-white;
  color: $color-black;
  position: relative;
  z-index: 1;

  h3 {
    font-size: px2rem(18);
    color: inherit;
  }

  p {
    margin: 0;
    font-size: px2rem(14);
  }

  a {
    display: block;
    white-space: nowrap;
  }

  figure {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
    margin: 0;
    background-color: $color-white;
  }

  figcaption {
    margin: 0 2rem 0 0;
    background-color: transparent;
    display: flex;
    font-weight: normal;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 1;
    flex-grow: 1;

    a.profile {
      margin-top: auto;
    }
  }

  .lt-plenarsaal__foto {
    width: 120px;
    height: auto;
    align-self: flex-start;

    margin-right: 0;

    @include media-breakpoint-up(sm) {
      margin-right: $spacer-2;
    }

  }

  .lt-plenarsaal__close {
    position: absolute !important;
    right: $spacer-2;
    top: $spacer-2;
    z-index: 1;
    cursor: pointer;
    font-size: $font-size-lg;
  }

  /* Mouseover Overlay */
  &.-simple {
    border: none;
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.8);
    color: $color-white;
    padding: 6px 12px;
    width: auto;
    white-space: nowrap;

    h3 {
      margin: 0;
      font-weight: 400;
      font-size: 12px;
    }
  }
}

/* Fraktionen */
.lt-plenarsaal__fraktionen {
  width: 100%;
  margin: 0 0 25px 20px;
  display: flex;
  flex-flow: row wrap;
}

.lt-plenarsaal__fraktion {
  display: block;
  font-weight: bold;
  line-height: 1.5;

  &:before {
    content: "";
    border-radius: 100%;
    width: $font-size-lg;
    height: $font-size-lg;
    display: inline-block;
    vertical-align: text-top;
    line-height: inherit;
    margin-right: $spacer-2;
  }
}

.lt-plenarsaal__fraktion-sitze {
  font-weight: normal;
}

@include media-breakpoint-up(lg) {

  .lt-plenarsaal {
    margin: inherit;
  }

  .lt-plenarsaal__image {
    margin: 0 15px 0 0;
  }

  .lt-plenarsaal__overlay {
    margin: 0;
    box-shadow: 0 3px 5px $color-gray-medium-light;
    //width: auto;
    max-width: 500px;
    position: absolute;

    &:not(.-simple) {
      //min-height: 156px;
    }
  }

  .lt-plenarsaal__fraktionen {
    margin: 0 0 25px 6px;
  }

}
